import { computed, ref, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { orderBy } from 'lodash-es';
import { storeToRefs } from 'pinia';

import { getCursorOfferStats, getCursorPromoOfferStats } from '../../api/distribution';

import {
  constructStartDate,
  constructEndDate,
  normalizeAndRemoveDiacritics,
} from '@/utils/helpers';
import { useStore } from '../../stores/store';
import { isMobile } from '../../services/helpers';
import { constructClientIds, getIconClass } from '../../utils/helpers';
import useSupplemental from '@/composables/supplemental/useSupplemental.js';

export default function useFilters() {
  const store = useStore();
  const router = useRouter();

  const {
    selectedFiltersExternal,
    searchTerm,
    timeFilters,
    filterSports: sports,
    filterCategories: categories,
    filterTournaments: tournaments,
    isLive,
    expandedSport,
    expandedCategory,
    expandedPromoOffer,
    numberOfFavouriteEvents,
    selectedView,
    inputRef,
    promoFilterOffers: promoOffers,
    promoFilterCatalogs: promoCatalogs,
    selectedPromoOffer,
    selectedPromoCatalog,
    searchToggled,
    offerGridLayouts,
    numberOfStreamedEvents,
  } = storeToRefs(store);

  const routeParams = computed(() => store.routeParams);

  const {
    setSelectedFiltersExternal,
    setSearchTerm,
    toggleSearch,
    setFilterData,
    getTranslation,
    changeOfferGridLayout,
  } = store;
  const { getSupplemental, getTournamentSupplementalName } = useSupplemental('overview');

  const sportSubmenusDisabled = computed(() => isLive.value);

  function toggleSport({ id, categories }) {
    if (!categories.length || sportSubmenusDisabled.value) return;
    expandedSport.value = expandedSport.value === id ? null : id;

    if (expandedPromoOffer.value) {
      expandedPromoOffer.value = null;
    }
  }

  function toggleCategory({ id, tournaments }) {
    if (!tournaments.length) return;
    expandedCategory.value = expandedCategory.value === id ? null : id;

    if (expandedPromoOffer.value) {
      expandedPromoOffer.value = null;
    }
  }

  function togglePromoOffer({ id } = {}, reset = false) {
    resetFullPromoShown();

    if (reset) {
      expandedPromoOffer.value = id;
    } else {
      expandedPromoOffer.value = expandedPromoOffer.value === id ? null : id;
    }

    if (expandedSport.value) {
      expandedSport.value = null;
    }
    if (expandedCategory.value) {
      expandedCategory.value = null;
    }

    if (expandedPromoOffer.value) {
      nextTick(() => {
        const element = document.querySelector(
          isMobile ? '.categories__wrapper' : '.sidebar__list--level-2',
        );

        element?.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
        });
      });
    }
  }

  const filterSports = computed(() =>
    orderBy(
      Object.values(sports.value).reduce((acc, sport) => {
        if (!sport.numberOfEvents) return acc;

        return [
          ...acc,
          {
            ...sport,
            ...(isLive.value && isMobile
              ? {}
              : {
                  categories: orderBy(
                    Object.values(categories.value).reduce((categoryArr, category) => {
                      if (!category.numberOfEvents) return categoryArr;

                      return category.sportId === sport.id
                        ? [
                            ...categoryArr,
                            {
                              ...category,
                              tournaments: orderBy(
                                Object.values(tournaments.value).filter(
                                  (tournament) =>
                                    tournament.numberOfEvents &&
                                    tournament.categoryId === category.id,
                                ),
                                [
                                  isLive.value ? 'positionLive' : 'positionPrematch',
                                  ({ name }) => normalizeAndRemoveDiacritics(name),
                                ],
                              ),
                            },
                          ]
                        : categoryArr;
                    }, []),
                    [
                      isLive.value ? 'positionLive' : 'positionPrematch',
                      ({ name }) => normalizeAndRemoveDiacritics(name),
                    ],
                  ),
                }),
          },
        ];
      }, []),
      [
        isLive.value ? 'positionLive' : 'positionPrematch',
        ({ name }) => normalizeAndRemoveDiacritics(name),
      ],
    ),
  );

  const promoFilterOffers = computed(() => {
    return orderBy(
      Object.values(promoOffers.value).reduce((acc, offer) => {
        if (!offer.numberOfEvents) return acc;

        return [
          ...acc,
          {
            ...offer,
            ...{
              catalogs: orderBy(
                Object.values(promoCatalogs.value).reduce((catalogArr, catalog) => {
                  if (!catalog.numberOfEvents) return catalogArr;

                  return catalog.promotedOfferId === offer.id
                    ? [
                        ...catalogArr,
                        {
                          ...catalog,
                        },
                      ]
                    : catalogArr;
                }, []),
                ['position', ({ name }) => normalizeAndRemoveDiacritics(name)],
              ),
            },
          },
        ];
      }, []),
      ['position', ({ name }) => normalizeAndRemoveDiacritics(name)],
    );
  });

  async function loadOfferStats() {
    const filter = selectedFiltersExternal.value.time;
    const objToSend = {};

    if (filter && filter !== 'all') {
      objToSend.startsAtFrom = timeFilters.value[filter].date ?? constructStartDate();
      objToSend.startsAtTo =
        timeFilters.value[filter].endDate ??
        constructEndDate(objToSend.startsAtFrom, 0) ??
        constructEndDate();
    }
    try {
      const { data } = await getCursorOfferStats(
        {
          ...objToSend,
          clientIds: constructClientIds(),
          ...(store.config?.supplemental?.list
            ? { supplementalNameTypeIds: store.config.supplemental.list }
            : {}),
        },
        isLive.value ? '2' : selectedFiltersExternal.value.sortBy !== 'byTime' ? '1,2' : '1',
      );

      store.resetFilterData();
      setFilterData(data);
    } catch (error) {
      console.log(`We've encountered error loading number of cursor offer statistics.`, error);
    }
  }

  let statsInterval = null;
  function createStatsPeriodicUpdate(fetchFresh) {
    async function updateFilterdata() {
      try {
        const response = await getCursorOfferStats(
          {
            clientIds: constructClientIds(),
            ...(store.config?.supplemental?.list
              ? { supplementalNameTypeIds: store.config.supplemental.list }
              : {}),
          },
          isLive.value ? '2' : selectedFiltersExternal.value.sortBy !== 'byTime' ? '1,2' : '1',
          store.filterCursorId,
        );
        store.setFilterData(response.data, 2);
        store.setFilterData(response.data);

        if (store.config.promotedOffer) {
          const response = await getCursorPromoOfferStats(
            { clientIds: constructClientIds() },
            isLive.value ? '2' : '1',
            store.promoFilterCursorId,
          );

          store.setPromoOfferFilterData(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (fetchFresh) updateFilterdata();
    statsInterval = setInterval(async () => updateFilterdata(), 30000);
  }

  function terminateStatsPeriodicUpdate() {
    clearInterval(statsInterval);
  }

  const sportName = computed(() => {
    if (selectedFiltersExternal.value.sport?.name) return selectedFiltersExternal.value.sport.name;

    const sportId =
      selectedFiltersExternal.value.tournament?.sportId ??
      selectedFiltersExternal.value.category?.sportId ??
      selectedFiltersExternal.value.sport?.id ??
      null;

    return sports.value[sportId]?.name;
  });

  const activeOfferFilter = computed(() => {
    const {
      sport: sportFromRoute,
      category,
      tournament,
      favourites,
      promotedOffer,
      catalog,
    } = routeParams.value;

    if (tournament) return getTournamentSupplementalName(store.filterTournaments[tournament]);
    if (category) return getSupplemental(store.filterCategories[category]);
    if (sportFromRoute) return getSupplemental(store.filterSports[sportFromRoute]);
    if (promotedOffer) {
      return promotedOffer === 'all'
        ? getTranslation('top_offer')
        : store.promoFilterOffers[promotedOffer]?.name;
    }
    if (catalog) return store.promoFilterCatalogs[catalog]?.name;
    if (favourites) return getTranslation('general_favourites');

    if (store.config?.defaultRoute.prematch.offer === 'sport') {
      const { sport: sportFilterExternal } = selectedFiltersExternal.value;
      return !sportFromRoute ? getSupplemental(sportFilterExternal) : '';
    } else {
      const [, firstPromoOffer] = Object.entries(store.promoFilterOffers)?.[0] ?? [];

      if (firstPromoOffer) {
        return firstPromoOffer?.name ?? '';
      } else {
        const [firstSport] = filterSports.value;

        return firstSport?.name;
      }
    }
  });

  function selectAllFilter() {
    router.push({
      path: '/live/d_all',
      query: router.currentRoute.value.query,
    });
  }

  function handleMetaRouteChange({
    sport,
    category,
    tournament,
    favourites,
    outrights,
    streamedEvents,
    promoOffer,
    catalog,
    entryPoint,
  }) {
    if (favourites) {
      window.pushEventToGoogleTagManager('favorite_events_selected');
      router.push({
        path: `${isLive.value ? '/live' : ''}/d_${selectedFiltersExternal.value.time}/f_true`,
        query: router.currentRoute.value.query,
      });
    } else if (outrights) {
      router.push({
        path: `/v_outrights`,
        query: router.currentRoute.value.query,
      });
    } else if (streamedEvents) {
      window.pushEventToGoogleTagManager('stream_events_selected');
      router.push({
        path: `/live/d_all/se_true`,
        query: router.currentRoute.value.query,
      });
    } else if (promoOffer || catalog) {
      let clickedFilter = '';

      if (promoOffer) {
        clickedFilter = `po_${promoOffer.id === 'all' ? store.selectedPromoOffer : promoOffer.id}`;
        window.pushEventToGoogleTagManager('promoted_offer_selected', {
          id: promoOffer.id,
          name: promoOffer.name,
          position: promoOffer.position,
          entry_point: entryPoint,
        });
      }
      if (catalog) {
        clickedFilter = `ctg_${catalog.id}`;
        window.pushEventToGoogleTagManager('promoted_offer_catalog_selected', {
          id: catalog.id,
          promoted_offer_id: catalog.promotedOfferId,
          name: catalog.name,
          position: catalog.position,
          entry_point: entryPoint,
        });
      }

      router.push({
        path: `${isLive.value ? '/live' : ''}/d_${
          selectedFiltersExternal.value.time
        }${clickedFilter ? '/' + clickedFilter : ''}`,
        query: router.currentRoute.value.query,
      });
    } else {
      let clickedFilter = '';

      if (sport) {
        clickedFilter = `s_${sport.id}`;
        window.pushEventToGoogleTagManager('click_on_sport', {
          id: sport.id,
          name: sport.name,
          position: sport[isLive.value ? 'positionLive' : 'positionPrematch'],
          entry_point: entryPoint,
        });
      }
      if (category) {
        clickedFilter = `c_${category.id}`;
        window.pushEventToGoogleTagManager('click_on_category', {
          id: category.id,
          sport_id: category.sportId,
          name: category.name,
          position: category[isLive.value ? 'positionLive' : 'positionPrematch'],
          entry_point: entryPoint,
        });
      }
      if (tournament) {
        clickedFilter = `t_${tournament.id}`;
        window.pushEventToGoogleTagManager('click_on_tournament', {
          id: tournament.id,
          sport_id: tournament.sportId,
          category_id: tournament.categoryId,
          name: tournament.name,
          entry_point: entryPoint,
        });
      }

      router.push({
        path: `${isLive.value ? '/live' : ''}/d_${
          selectedFiltersExternal.value.time
        }${clickedFilter ? '/' + clickedFilter : ''}`,
        query: router.currentRoute.value.query,
      });
    }
  }

  function handleTimeRouteChange(val) {
    const { path, query } = router.currentRoute.value;

    let clickedFilter = '';
    const { sport, category, tournament, promotedOffer } = routeParams.value;

    if (sport) clickedFilter = `s_${sport}`;
    if (category) clickedFilter = `c_${category}`;
    if (tournament) clickedFilter = `t_${tournament}`;
    if (promotedOffer) clickedFilter = `po_${promotedOffer}`;
    if (!sport && !category && !tournament && !selectedFiltersExternal.value.promotedOffer) {
      const firstSport = orderBy(
        store.filterSports,
        isLive.value ? 'positionLive' : 'positionPrematch',
      )?.[0]?.id;

      clickedFilter = `s_${firstSport}`;
    }

    let newPath = routeParams.value.time
      ? path.replace(routeParams.value.time, val)
      : `${path === '/' ? '' : path}/d_${val}/${clickedFilter}`;
    newPath = newPath.replace(`/e_${routeParams.value.event}`, '');

    window.pushEventToGoogleTagManager('time_filter_selected', { value: val });

    router.push({
      path: val === 'live' ? '/live' : newPath,
      query,
    });
  }

  const isFullPromoShown = ref(null);

  function toggleRemainingOffers(offerId) {
    isFullPromoShown.value = isFullPromoShown.value === offerId ? null : offerId;
    window.pushEventToGoogleTagManager(
      isFullPromoShown.value ? 'show_more_clicked' : 'show_less_clicked',
    );
  }

  function resetFullPromoShown() {
    isFullPromoShown.value = null;
  }

  const promoOfferRemainingNumberOfCatalogs = computed(() => {
    return promoFilterOffers.value.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val.catalogs.length - (isMobile ? 10 : 5),
      };
    }, {});
  });

  function sliceCatalogs(offer) {
    const catalogsToShow = isMobile ? 10 : 5;
    const toSlice = isFullPromoShown.value ? undefined : catalogsToShow;

    return offer.catalogs.slice(0, toSlice);
  }

  const promoFilterOfferExtended = computed(() => {
    const allFilter = { id: 'all', name: store.getTranslation('general_all') };

    return [
      allFilter,
      ...(promoFilterOffers.value.find((offer) => offer.id === selectedPromoOffer.value)
        ?.catalogs ?? []),
    ];
  });

  const displayOutrights = computed(() => {
    if (!store.config?.outrightsView) return false;

    return Object.values(store.outrightsSportsNumberOfEvents).length && !isLive.value;
  });

  return {
    selectedFiltersExternal,
    setSelectedFiltersExternal,
    searchTerm,
    setSearchTerm,
    timeFilters,
    inputRef,
    searchToggled,
    toggleSearch,
    expandedSport,
    toggleSport,
    expandedCategory,
    togglePromoOffer,
    expandedPromoOffer,
    toggleCategory,
    sports: filterSports,
    sportName,
    sportSubmenusDisabled,
    isLive,
    store,
    createStatsPeriodicUpdate,
    terminateStatsPeriodicUpdate,
    activeOfferFilter,
    numberOfFavouriteEvents,
    getTranslation,
    selectAllFilter,
    loadOfferStats,
    router,
    routeParams,
    handleMetaRouteChange,
    handleTimeRouteChange,

    displayOutrights,

    selectedView,
    promoFilterOffers,
    isFullPromoShown,
    toggleRemainingOffers,
    promoOfferRemainingNumberOfCatalogs,
    sliceCatalogs,
    selectedPromoOffer,
    selectedPromoCatalog,
    promoFilterOfferExtended,
    resetFullPromoShown,

    getIcon: getIconClass,

    offerGridLayouts,
    changeOfferGridLayout,

    numberOfStreamedEvents,

    getTournamentSupplementalName,
  };
}
