<script setup>
import { onBeforeMount, ref } from 'vue';

import { getExternalBetDetailsByRegularBetId } from '@/api/bet-list.js';
import getTranslations from '@/api/translations.js';
import { useStore } from '@/stores/store';
import { useBetslipStore } from '@/stores/BetslipStore.js';
import { isMobile } from '@/utils/helpers.js';
import createChildIntegratorInstance from '@/services/child-integrator.js';

import Spinner from '@/components/common/Spinner.vue';
import BetDetailsMobile from '@/components/mobile/common/BetDetails.vue';
import BetDetailsDesktop from '@/components/desktop/common/BetDetails.vue';

const store = useStore();
const betslipStore = useBetslipStore();

const loading = ref(false);
const loadMobileView = ref(null);

const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));

async function loadBetDetails(id) {
  loading.value = true;

  try {
    const { bet } = await getExternalBetDetailsByRegularBetId(id);

    store.betDetails = bet;
    store.betDetailsSelections = store.mapBetDetailsSelections(bet.selections);

    loading.value = false;
  } catch (error) {
    console.log(`We've encountered error loading bet: ${id}.`, error);
  }
}

const loadApp = async (data) => {
  loadMobileView.value = data.loadMobileView;

  store.setTranslations(await getTranslations());
  if (data.betId) loadBetDetails(data.betId);
};

emitter.on('load-app', (data) => loadApp(data));

onBeforeMount(() => {
  betslipStore.sendBetslipWorkerMessage('generate_pascal_triangle', {
    maxSelections: 30,
  });

  if (queryParams?.standalone && queryParams?.betId) {
    loadApp({ loadMobileView: queryParams?.loadMobileView ?? null, betId: queryParams?.betId });
  }
});

createChildIntegratorInstance({
  onLoad: ({ context, settings, gameOptions, punter = {} }) => {
    window.tenantUuid = context.tenantId;
    window.languageCode = settings.activeLocale;

    if (punter?.identity?.id) {
      window.punter = { id: punter.identity.id };
    }

    loadApp({
      betId: gameOptions?.betId ?? null,
      loadMobileView: gameOptions?.loadMobileView ?? null,
    });
  },

  onGameOptionsUpdated: (data) => {
    if (data?.betId) loadBetDetails(data.betId);
  },

  onPunterDetailsUpdated: (data) => {
    window.punter = data;
  },

  onPunterSessionUpdated: (data) => {
    window.punterToken = data.punterToken;

    if (queryParams?.standalone && queryParams?.betId) {
      loadBetDetails(queryParams.betId);
    }
  },

  onPunterSessionDestroyed: () => {
    window.punter = null;
    window.punterToken = null;
  },
});
</script>

<template>
  <component
    :is="loadMobileView || isMobile ? BetDetailsMobile : BetDetailsDesktop"
    v-if="!loading && store.betDetails"
    :bet="store.betDetails"
    hide-back-button />
  <Spinner v-else />
</template>
