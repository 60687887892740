<template>
  <div class="bet-details-body">
    <div class="bet-details-body__selections-list">
      <div class="bet-details-body__selections-header">
        <div class="bet-details-body__status-column column"></div>
        <div class="bet-details-body__match-column column">
          {{ store.getTranslation('general_match') }}
        </div>
        <div class="bet-details-body__time-column column">
          {{ store.getTranslation('general_time') }}
        </div>
        <div class="bet-details-body__market-column column">
          {{ store.getTranslation('market') }}
        </div>
        <div class="bet-details-body__outcome-column column">
          {{ store.getTranslation('general_outcomes') }}
        </div>
        <div class="bet-details-body__odd-column column">
          {{ store.getTranslation('general_odd') }}
        </div>
        <div class="bet-details-body__result-column column">
          {{ store.getTranslation('result') }}
        </div>
      </div>

      <template
        v-for="[id, selection] in store.betDetailsSelections"
        :key="id">
        <div
          class="bet-details-body__selection-row"
          :class="{
            'bet-details-body__selection-row--multiline': selection.eventMarketOutcomes.length > 1,
          }">
          <div class="bet-details-body__status-column column">
            <StatusBadge
              :status="getSelectionResolutionStatusLabel(selection.resolutionStatuses)"
              :type="getSelectionResolutionStatus(selection.resolutionStatuses)" />
          </div>

          <div class="bet-details-body__match-column column text-truncate">
            <div
              class="bet-details-body__match-name text-truncate"
              :title="selection.event.name">
              {{ selection.event.name }}
            </div>
            <div
              v-if="selection.banker && isSystemBet"
              class="bet-details-body__selection-banker">
              {{ store.getTranslation('general_fix') }}
            </div>
          </div>

          <div class="bet-details-body__time-column column faded">
            <span
              v-if="selection?.detailedPlayStatus == 7"
              class="time-badge">
              {{ store.getTranslation('live_metadata.general.status_interrupted') }}
            </span>
            <span
              v-else-if="selection?.detailedPlayStatus == 8"
              class="time-badge">
              {{ store.getTranslation('live_metadata.general.status_suspended') }}
            </span>
            <span
              v-else-if="selection.metadata"
              class="time-badge">
              <Icon icon="n-i-live-b" />
              <span v-if="selection.metadata.displayEventStatus">{{
                selection.metadata.eventStatus
              }}</span>
              <span v-if="selection.metadata.displayEventStatus && selection.metadata?.eventTime">
                -
              </span>
              <span v-if="selection.metadata.eventTime">{{ selection.metadata.eventTime }} </span>
              <span v-if="selection.metadata.eventExtraTime"
                >+{{ selection.metadata.eventExtraTime }}
              </span>
            </span>
            <span v-else>{{ formatSelectionDate(selection.event.startAt) }}</span>
          </div>

          <div
            class="bet-details-body__market-column column text-truncate"
            :class="{ highlighted: selection.eventMarketOutcomes.length > 1 }"
            :title="
              selection.eventMarketOutcomes.length === 1
                ? selection.eventMarketOutcomes[0].eventMarket.name
                : ''
            ">
            {{
              selection.eventMarketOutcomes.length > 1
                ? store.getTranslation('bet_builder')
                : selection.eventMarketOutcomes[0].eventMarket.name
            }}
          </div>

          <div
            v-tooltip="{
              content: selection.eventMarketOutcomes[0].name,
              disabled: selection.eventMarketOutcomes.length > 1,
            }"
            class="bet-details-body__outcome-column column">
            {{
              selection.eventMarketOutcomes.length > 1 ? '-' : selection.eventMarketOutcomes[0].name
            }}
          </div>

          <div class="bet-details-body__odd-column column">
            <span
              v-if="shouldShowSettleOdds(selection.resolutionStatuses)"
              class="line-through"
              >{{ getRequestedOdds(selection.odds) }}</span
            >
            <span>{{
              getDisplayOdds({
                odds: selection.odds,
                resolutionStatuses: selection.resolutionStatuses,
              })
            }}</span>
          </div>

          <div
            class="bet-details-body__result-column column"
            :class="{
              highlighted:
                selection.metadata?.currentScoreInSets || selection.metadata?.currentGameScore,
            }">
            <template
              v-if="selection.metadata?.currentScoreInSets || selection.metadata?.currentGameScore">
              <span>
                {{
                  (selection.metadata?.currentScoreInSets || selection.metadata?.currentGameScore)
                    .home
                }}
              </span>
              <span>:</span>
              <span>
                {{
                  (selection.metadata?.currentScoreInSets || selection.metadata?.currentGameScore)
                    .away
                }}
              </span>
            </template>

            <template v-else>-</template>
          </div>
        </div>

        <template v-if="selection.eventMarketOutcomes.length > 1">
          <div
            v-for="marketOutcomeData in selection.eventMarketOutcomes"
            :key="marketOutcomeData.id"
            class="bet-details-body__selection-row bet-details-body__selection-row--multiline">
            <div class="bet-details-body__status-column column">
              <StatusBadge
                :status="getSelectionResolutionStatusLabel(marketOutcomeData.resolutionStatuses)"
                :type="getSelectionResolutionStatus(marketOutcomeData.resolutionStatuses)" />
            </div>
            <div class="bet-details-body__match-column column text-truncate"></div>
            <div class="bet-details-body__time-column column faded"></div>

            <div
              class="bet-details-body__market-column column text-truncate"
              :title="marketOutcomeData.eventMarket.name">
              {{ marketOutcomeData.eventMarket.name }}
            </div>

            <div class="bet-details-body__outcome-column column">
              {{ marketOutcomeData.name }}
            </div>

            <div class="bet-details-body__odd-column column"></div>
            <div class="bet-details-body__result-column column"></div>
          </div>
        </template>
      </template>
    </div>

    <div class="bet-details-body__meta">
      <div
        v-if="totalOdds && !isSystemBet"
        class="bet-details-body__meta-row">
        <div class="bet-details-body__meta-label">{{ store.getTranslation('total_odds') }}</div>
        <div class="bet-details-body__meta-value">
          {{ totalOdds }}
        </div>
      </div>

      <div
        v-if="isSystemBet"
        class="bet-details-body__meta-row">
        <div class="bet-details-body__meta-label">
          {{ store.getTranslation('total_combinations') }}
        </div>
        <div class="bet-details-body__meta-value">{{ totalCombinations }}</div>
      </div>

      <div class="bet-details-body__meta-row bet-details-body__meta-row--double">
        <div class="">
          <template v-if="desiredProfit">
            <div class="bet-details-body__meta-label">
              {{ store.getTranslation('desired_profit') }}
            </div>
            <div class="bet-details-body__meta-value">
              {{ desiredProfit }}
            </div>
          </template>
          <template v-else>
            <div class="bet-details-body__meta-label">{{ store.getTranslation('payin') }}</div>
            <div class="bet-details-body__meta-value">
              {{ formatNumberWithCurrency(bet.payin.stake.amount, bet.currency) }}
            </div>
          </template>
        </div>

        <div v-if="bet.payin.taxes[0] && bet.payin.taxes[0].amount">
          <div class="bet-details-body__meta-label">{{ store.getTranslation('payin_tax') }}</div>
          <div class="bet-details-body__meta-value">{{ bet.payin.taxes[0].amount.toFixed(2) }}</div>
        </div>
      </div>

      <div class="bet-details-body__meta-row bet-details-body__meta-row--double">
        <div class="">
          <div class="bet-details-body__meta-label highlight bold">
            {{ payoutLabel }}
          </div>
          <div class="bet-details-body__meta-value highlight bold">
            {{ formatNumberWithCurrency(winningsToDisplay.total, bet.currency) }}
          </div>
        </div>

        <div
          v-if="minWinnings && isSystemBet"
          class="">
          <div class="bet-details-body__meta-label">
            {{ store.getTranslation('min_payout') }}
          </div>
          <div class="bet-details-body__meta-value">
            {{ formatNumberWithCurrency(minWinnings.total, bet.currency) }}
          </div>
        </div>

        <div v-if="winningsToDisplay.taxes[0] && winningsToDisplay.taxes[0].amount">
          <div class="bet-details-body__meta-label">{{ store.getTranslation('payout_tax') }}</div>
          <div class="bet-details-body__meta-value">
            {{ winningsToDisplay.taxes[0].amount.toFixed(2) }}
          </div>
        </div>
      </div>

      <div
        v-if="bonusAmount.payin"
        class="bet-details-body__meta-row">
        <div class="bet-details-body__meta-label">
          {{ store.getTranslation('bet_bonus.payin') }}
          <!-- ({{ bet.betBonus?.percentage }}%) -->
        </div>
        <div class="bet-details-body__meta-value">
          {{ formatNumberWithCurrency(bonusAmount.payin, bet.currency) }}
        </div>
      </div>

      <div
        v-if="bonusAmount.winnings"
        class="bet-details-body__meta-row">
        <div class="bet-details-body__meta-label">
          {{ store.getTranslation('bet_bonus.winnings') }}
          <!-- ({{ bet.betBonus?.percentage }}%) -->
        </div>
        <div class="bet-details-body__meta-value">
          {{ formatNumberWithCurrency(bonusAmount.winnings, bet.currency) }}
        </div>
      </div>
    </div>

    <div
      v-if="bet.autoCashoutRule"
      class="bet-details-body__cashout-rule">
      <div class="bet-details-body__cashout-rule-info">
        <div class="bet-details-body__cashout-rule-label">
          {{ store.getTranslation('autocashout.rule.active') }}:
        </div>
        <div class="bet-details-body__cashout-rule-value">
          {{
            store.getTranslation('autocashout.setup.amount', {
              value: formatNumberWithCurrency(bet.autoCashoutRule.amount, bet.currency),
            })
          }}
        </div>
      </div>

      <Button
        class="bet-details-body__cashout-rule-delete"
        @click="store.toggleAutoCashoutDeleteModal(true)">
        <Icon
          icon="n-i-trash"
          size="xl" />
      </Button>
    </div>

    <teleport to="#modals">
      <AutoCashoutModal
        v-if="store.autoCashoutDeleteModalDisplayed"
        :bet="bet"
        :max-win="+winningsToDisplay?.total"
        action="delete"
        @close="store.toggleAutoCashoutDeleteModal" />
    </teleport>
  </div>
</template>

<script setup>
import { toRef } from 'vue';
import useBetDetails from '@/composables/bet-list/useBetDetails';
import StatusBadge from '@/components/common/StatusBadge.vue';
import Icon from '@/components/common/Icon.vue';
import Button from '@/components/common/Button.vue';
import AutoCashoutModal from '@/components/desktop/bet-list/AutoCashoutModal.vue';

import { useStore } from '@/stores/store';
import { formatNumberWithCurrency } from '@/utils/helpers';

const props = defineProps({
  bet: {
    type: Object,
    required: true,
    default: () => {},
  },
  showResults: {
    type: Boolean,
    required: false,
    default: false,
  },
  showStatus: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const store = useStore();

const betRef = toRef(props, 'bet');
const {
  totalOdds,
  isSystemBet,
  totalCombinations,
  formatSelectionDate,
  winningsToDisplay,
  minWinnings,
  payoutLabel,
  bonusAmount,
  getSelectionResolutionStatus,
  getSelectionResolutionStatusLabel,
  getRequestedOdds,
  getDisplayOdds,
  shouldShowSettleOdds,
  desiredProfit,
} = useBetDetails(betRef);
</script>

<style lang="scss">
:root {
  --bet-details-body-table-row-bg-color: var(--card-section-2);
  --bet-details-body-table-row-header-color: var(--text-primary-2);
  --bet-details-body-table-row-color: var(--text-primary-1);
  --bet-details-body-table-column-highlighted-color: var(--primary);
  --bet-details-body-banker-bg-color: var(--button);
  --bet-details-body-banker-stroke-color: var(--button-stroke);
  --bet-details-body-banker-color: var(--text-primary-1);
  --bet-details-body-meta-row-bg-color: var(--card-section-2);
  --bet-details-body-meta-label-highlight: var(--primary);
  --bet-details-body-cashout-rule-bg-color: var(--card-section-2);
}
</style>

<style scoped lang="scss">
.bet-details-body {
  .column {
    &.faded {
      color: var(--text-primary-2);
    }

    &.highlighted {
      color: var(--bet-details-body-table-column-highlighted-color);
    }

    &:last-child {
      text-align: right;
    }
  }

  &__selections-list {
    margin-bottom: var(--gap-16);
    display: grid;
    grid-template-rows: auto 1fr;
  }

  &__selections-header {
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  &__selections-header,
  &__selection-row {
    align-items: center;
    min-height: 2.5rem;
    color: var(--bet-details-body-table-row-header-color);
    padding: var(--gap-base) var(--gap-16);
    border-radius: var(--border-radius-xs);
    margin-bottom: 0.125rem;

    display: grid;
    grid-template-columns: minmax(86px, auto) minmax(80px, 2.5fr) 1fr 1.5fr 1.5fr 1fr 1fr;
    gap: 5px;
  }

  &__selection-row,
  &__combination-row {
    color: var(--bet-details-body-table-row-color);
  }

  &__selection-row,
  &__selections-header,
  &__combination-row {
    font-size: 0.875rem;
    background-color: var(--bet-details-body-table-row-bg-color);
  }

  &__selection-row {
    &--multiline {
      margin-bottom: 0;
    }
  }

  &__combination-row {
    background-color: var(--bet-details-body-combination-row-bg-color);
  }

  &__match-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }

  &__selection-banker {
    min-width: 1.125rem;
    height: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    font-size: var(--font-small);
    font-weight: 500;
    border: 1px solid var(--bet-details-body-banker-stroke-color);
    border-radius: var(--border-radius);
    color: var(--bet-details-body-banker-color);
    background: var(--bet-details-body-banker-bg-color);
    text-transform: uppercase;
  }

  .time-badge {
    display: inline-flex;
    align-items: center;
    padding: var(--gap-2) var(--gap-4);
    font-size: var(--font-small);
    font-weight: 500;
    border-radius: var(--border-radius);
    background: var(--primary);
    color: var(--text-secondary-1);

    .icon {
      margin-right: 0.25rem;
    }
  }

  &__time-column {
    flex: 1 1 15%;
  }

  &__market-column,
  &__outcome-column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__market-column {
    flex: 1 1 20%;
  }

  &__outcome-column {
    flex: 1 1 15%;
  }

  &__odd-column {
    flex: 1 1 8%;
    display: flex;
    align-items: center;
    gap: var(--gap-6);

    .line-through {
      color: var(--text-primary-2);
      text-decoration: line-through;
      font-size: 0.75rem;
    }
  }

  &__meta {
    padding: 1rem;
    background-color: var(--bet-details-body-meta-row-bg-color);
    border-radius: var(--border-radius-xs);

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--bet-details-body-meta-row-bg-color);
      padding: 0;
      line-height: 22px;
      text-transform: capitalize;

      &--double {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          &:nth-child(2) {
            opacity: 0.5;
          }
        }
      }
    }

    &-label,
    &-value {
      font-size: 14px;

      &.bold {
        font-weight: 700;
      }

      &.highlight {
        color: var(--bet-details-body-meta-label-highlight);
      }
    }
  }

  &__cashout-rule {
    display: flex;
    align-items: center;
    padding: var(--gap-12) var(--gap-16);
    background-color: var(--bet-details-body-cashout-rule-bg-color);
    border-radius: var(--border-radius-xs);
    margin-top: var(--gap-12);

    &-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: var(--gap-2);
    }

    &-label {
      color: var(--success);
      font-size: var(--font-medium);
      font-weight: 500;
    }

    &-value {
      font-size: var(--font-base);
    }

    &-delete {
      width: 2rem;
      height: 2rem;
      padding: 0;
      background-color: transparent;

      .icon {
        font-size: var(--font-medium);
        color: var(--text-primary-2);
      }
    }
  }
}
</style>
