import dayjs from 'dayjs';

export async function loadDayjsLocale(locale: string) {
  const localeMapping: Record<string, string> = {
    no: 'nb',
  };

  const resolvedLocale = localeMapping?.[locale] ?? locale;

  try {
    await import(`@/../node_modules/dayjs/locale/${resolvedLocale}.js`);
    dayjs.locale(resolvedLocale);
  } catch (e) {
    console.log(
      `An error occured while loading dayjs locale ${resolvedLocale}, app will use en locale for date formats.`,
      e,
    );
  }
}
