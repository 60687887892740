<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useSwipe, onClickOutside } from '@vueuse/core';
import { useStore } from '@/stores/store';

const store = useStore();

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: '',
  },
  defaultBgAlpha: {
    type: Number,
    default: 0.7,
  },
  transitionSpeed: {
    type: Number,
    default: 200,
  },
  maxHeightRatio: {
    type: Number,
    required: false,
    default: 0.9,
  },
  useMaxHeightAsInitialValue: {
    type: Boolean,
    required: false,
    default: false,
  },
  externallyCalculatedHeight: {
    type: [Number, null],
    required: false,
    default: null,
  },
  contentPadding: {
    type: String,
    required: false,
    default: 'var(--gap-16) var(--gap-24) var(--gap-32)',
  },
});

const emit = defineEmits(['toggleSheet', 'notifySwipeState']);

const swipePan = ref(null);
const bottomSheet = ref(null);
const maxHeight = ref(window.innerHeight * props.maxHeightRatio);

const contentPadingRef = ref(props.contentPadding);

let initialHeight = 0;
const height = ref();

function closeSheet() {
  height.value = 0;
  bgAlpha.value = 0;

  const timeout = setTimeout(() => {
    clearTimeout(timeout);
    emit('toggleSheet');
  }, props.transitionSpeed);
}

const bgAlpha = ref(props.defaultBgAlpha);
const { lengthY, isSwiping } = useSwipe(swipePan, {
  passive: true,
  threshold: 0,

  onSwipeStart: () => {
    initialHeight = height.value;
    emit('notifySwipeState', 'START');
  },
  onSwipe: () => {
    if (lengthY.value < 0) {
      height.value = initialHeight - Math.abs(lengthY.value);

      const alpha = (initialHeight - height.value) / initialHeight;
      bgAlpha.value = Math.min(Math.max(props.defaultBgAlpha - alpha, 0), 1);
    }
  },
  onSwipeEnd: () => {
    const lengthYAbsed = Math.abs(lengthY.value);

    if (lengthYAbsed > initialHeight / 1.7) closeSheet();
    else {
      height.value = initialHeight;
      bgAlpha.value = props.defaultBgAlpha;
      emit('notifySwipeState', 'END');
    }
  },
});

onClickOutside(bottomSheet, () => closeSheet());

function recalculateHeight(nContentHeight = null) {
  const headerHeight = document.querySelector('.bottom-sheet__header-wrapper').clientHeight;
  const contentHeight =
    nContentHeight ?? document.querySelector('.bottom-sheet__content').clientHeight;

  height.value = headerHeight + contentHeight;
  maxHeight.value = window.innerHeight * props.maxHeightRatio;

  const tempHeight = headerHeight + contentHeight;
  height.value = tempHeight > maxHeight.value ? maxHeight.value : tempHeight;

  return height.value - headerHeight;
}

defineExpose({
  recalculateHeight,
});

onMounted(() => {
  height.value = props.useMaxHeightAsInitialValue
    ? maxHeight.value
    : bottomSheet.value.clientHeight;
  document.querySelector('html').style = 'overflow-y: hidden';
});

onBeforeUnmount(() => {
  document.querySelector('html').style = 'overflow-y: auto';
});
</script>

<template>
  <Transition
    name="bottom-sheet"
    appear>
    <div
      :id="`bottomsheet-view--${store.offerView}`"
      class="bottom-sheet__wrapper"
      :style="{
        background: `rgba(0, 0, 0, ${bgAlpha})`,
        transitionDuration: isSwiping ? '' : `${transitionSpeed}ms`,
      }">
      <div
        ref="bottomSheet"
        class="bottom-sheet__container"
        :style="{
          height: `${height}px`,
          maxHeight: `${maxHeight}px`,
          transitionDuration: isSwiping ? '' : `${transitionSpeed}ms`,
        }">
        <div
          ref="swipePan"
          class="bottom-sheet__header-wrapper">
          <div class="bottom-sheet__swipe-pan" />

          <div class="bottom-sheet__header">
            <slot name="header">
              <h3 v-if="title">{{ title }}</h3>
            </slot>
          </div>
        </div>

        <div class="bottom-sheet__content">
          <slot></slot>
        </div>

        <slot name="footer"></slot>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
:root {
  --bottom-sheet-bg: var(--card-background);
  --bottom-sheet-overlay-color: rgba(0, 0, 0, 0.7);
  --bottom-sheet-shadow-color: var(--shadow-alpha);
  --bottom-sheet-swipe-pan-color: var(--text-primary-3);
}

#bottomsheet-view--indonesian {
  .bottom-sheet__content {
    .btn {
      border-radius: 0.25rem;
    }
  }
}
</style>

<style lang="scss" scoped>
.bottom-sheet {
  &__wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--bottom-sheet-overlay-color);
    transition-property: all;
    transition-timing-function: ease-in-out;
    @include z-index(overlay);
    overscroll-behavior: contain;
  }

  &__container {
    width: 100%;
    position: fixed;
    bottom: 0;
    overflow: hidden;
    background-color: var(--bottom-sheet-bg);
    border-radius: 0.25rem 0.25rem 0 0;
    transition-property: all;
    transition-timing-function: ease-in-out;
    @include z-index(bottom-sheet);
    overscroll-behavior: contain;
  }

  &__header-wrapper {
    position: sticky;
    top: 0;
    width: 100%;
    @include z-index(bottom-sheet-header);
  }

  &__header {
    background-color: var(--bottom-sheet-bg);
    padding: var(--gap-24) var(--gap-24) var(--gap-12);
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    width: 100%;
  }

  &__swipe-pan {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 64px;
    height: 8px;
    background-color: var(--bottom-sheet-swipe-pan-color);
    border-radius: 0 0 8px 8px;
    @include z-index(bottom-sheet-swipe-pan);
  }

  &__content {
    position: relative;
    padding: v-bind(contentPadingRef);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
</style>
