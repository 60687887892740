const statusMap = {
  1: 'NS',
  2: '1H',
  3: 'HT',
  4: '2H',
  5: 'END',
  6: 'A. OT',
  7: 'FH OT',
  8: 'OT HT',
  9: '2H OT',
  10: 'END AF. OT.',
  11: 'AT PT',
  12: 'PT',
  13: 'END AT. PT',
};

const scorePeriodsMap = {
  1: '1H',
  2: '2H',
  3: 'OT',
  4: '1H OT',
  5: '2H OT',
  6: 'PT',
};

function mapSoccerPeriods(eventTypes) {
  const periods = eventTypes.find((type) => type.id === 3)?.periods ?? [];
  if (!periods.length) return [];

  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: scorePeriodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapSoccerEvent(event, shortTime) {
  const tempTime = event.events.find((event) => event.id === 7)?.value?.value || 0;
  const eventTime = shortTime && typeof tempTime === 'string' ? `${tempTime.split(':')[0]}'` : tempTime;
  const tempEventExtraTime = event.events.find((event) => event.id === 8)?.value?.value || 0;
  const eventExtraTime = shortTime && typeof tempEventExtraTime === 'string' ? `${tempEventExtraTime.split(':')[0]}'` : tempEventExtraTime;

  const redCards = event.events?.reduce(
    (acc, n) => {
      if ([11, 13].includes(n.id)) {
        acc.home += n?.value?.home || 0;
        acc.away += n?.value?.away || 0;
      }

      return acc;
    },
    { home: 0, away: 0 },
  ) ?? { home: 0, away: 0 };

  const currentGameScore = event.events.find((event) => event.id === 1)?.value ?? null;

  return {
    displayEventStatus: !eventTime,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapSoccerPeriods(event.events),
    currentGameScore,
    yellowCards: event.events.find((event) => event.id === 10)?.value ?? { home: 0, away: 0 },
    redCards,
    corners: event.events.find((event) => event.id === 12)?.value ?? { home: 0, away: 0 },
    eventTime,
    eventExtraTime,
    hasExtraDetails: true,
    compactModeScores: [currentGameScore],
  };
}
