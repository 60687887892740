import Decimal from 'decimal.js';

export const isMobile = /iphone|ipod|android|ie|blackberry|fennec/.test(
  navigator.userAgent.toLowerCase(),
);

export const convertToIntegerOdds = (odds, divider = 10000) =>
  new Decimal(odds ?? 0).dividedBy(divider).toDecimalPlaces(2);

export const convertDecimalOddsToInteger = (odds = 1) =>
  new Decimal(odds).times(10000).toDecimalPlaces(0).toNumber();

export const constructEndDate = (date = null, days = 0) =>
  dayjs(date ?? dayjs())
    ?.add(days, 'days')
    ?.set('hour', 23)
    ?.set('minute', 59)
    ?.set('second', 59)
    ?.format('YYYY-MM-DDTHH:mm:ss');

export const constructStartDate = (fromStartDay = false) => {
  const startDate = fromStartDay ? dayjs().startOf('day') : dayjs();
  return startDate.format('YYYY-MM-DDTHH:mm:ss');
};

export const formatNumberInput = (value) => {
  // Remove leading zeros if present
  const trimmedValue = value.replace(/^0+(?=\d)/, '');

  // Remove any non-numeric characters (allow dot or comma only)
  const sanitizedValue = trimmedValue.replace(/[^0-9.,]/g, '');

  // Replace any leading dots or commas with an empty string
  const dotValue = sanitizedValue.replace(/^[.,]+/, '');

  // Replace any remaining commas with dots (to standardize the decimal separator)
  const standardizedValue = dotValue.replace(/,/g, '.');

  // Ensure there is only one dot or comma (and up to two decimals)
  const regex = /^(\d*\.?\d{0,2}).*$/;

  const formattedValue = standardizedValue.replace(regex, '$1');

  return formattedValue.substring(0, 20);
};

export const normalizeAndRemoveDiacritics = (str) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const formatNumberWithCurrency = (number = '', currency, languageCode) =>
  (+number || 0).toLocaleString(languageCode || window.languageCode || 'en', {
    style: 'currency',
    currency: currency || window.currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const constructClientIds = (id = null) => {
  let clientIds = {
    channel: isMobile ? 'mobile' : 'desktop',
  };

  const punterId = id || window.punter?.id || null;
  if (punterId) clientIds.punterId = punterId;

  return clientIds;
};

export const supportedOddFormats = {
  decimal: 'decimal_odds',
  american: 'american_odds',
  fractional: 'fractional_odds',
  hongkong: 'hongkong_odds',
  indonesian: 'indonesian_odds',
  malaysian: 'malaysian_odds',
};

export const formatNumberWoCurrency = (number, fractionDigits = 2) =>
  (+number || 0)?.toLocaleString('en', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

export const getIconClass = (code, { isLargeFlag = false } = {}) => {
  if (!code) return '';

  if (code.startsWith('flag')) {
    const [, flag] = code.split('-');

    return `flag ${isLargeFlag ? `flag-lg flag-lg-${flag}` : code.toLowerCase()}`;
  }

  return code.toLowerCase();
};
