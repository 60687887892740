import { useBetslipStore } from '../../../stores/BetslipStore';
import { useStore } from '../../../stores/store';
import { mapBasketballEvent } from './basketballMapper';
import { mapIceHockeyEvent } from './iceHockeyMapper';
import { mapSoccerEvent } from './soccerMapper';
import { mapTableTennisEvent } from './tableTennisMapper';
import { mapTennisEvent } from './tennisMapper';
import { mapHandballEvent } from './handballMapper';
import { mapBaseballEvent } from './baseballMapper';
import { mapVolleyballEvent } from './volleyballMapper';
import { mapBadmintonEvent } from './badmintonMapper';
import { mapFutsalEvent } from './futsalMapper';
import { mapAmericanFootballEvent } from './americanFootballMapper';
import { mapRugbyEvent } from './rugbyMapper';
import { mapBeachVolleyEvent } from './beachVolleyMapper';
import { mapAussieRulesEvent } from './aussieRulesMapper';
import { mapBasketball3x3Event } from './basketball3x3Mapper';
import { mapBowlsEvent } from './bowlsMapper';
import { mapDartsEvent } from './dartsMapper';
import { mapFieldHockeyEvent } from './fieldHockeyMapper';
import { mapSnookerEvent } from './snookerMapper';
import { mapWaterpoloEvent } from './waterpoloMapper';
import { mapMMAEvent } from './mmaMapper';
import { mapSquashEvent } from './squashMapper';
import { mapPadelEvent } from './padelMapper';
import { mapKabaddiEvent } from './kabaddiMapper';
import { mapESoccerEvent } from './esoccerMapper';
import { mapEBasketballEvent } from './ebasketballMapper';
import { mapCounterStrikeEvent } from './counterstrikeMapper';
import { mapDota2Event } from './dota2Mapper';
import { mapLeagueOfLegendsEvent } from './leagueoflegendsMapper';
import { mapCricketEvent } from './cricketMapper';
import { mapBiathlonEvent } from './biathlonMapper';
import { mapFormula1Event } from './formula1Mapper';
import { mapGolfEvent } from './golfMapper';
import { mapMotoGPEvent } from './motogpMapper';
import { mapSkiJumpingEvent } from './skijumpingMapper';
import { mapNascarEvent } from './nascarMapper';
import { mapAlpineSkiingEvent } from '@/services/formatters/metadata/alpineskiingMapper.js';

const mapEventMetadata = (event) => {
  const shortEventTime = {
    '94f72bfb-c194-434d-936e-fd96e366cce9': true,
    'c32f5d09-40e6-4287-a84f-6e543f5d1419': true,
  }[window.tenantUuid];

  switch (event.sportId) {
    case 1:
      return mapSoccerEvent(event, shortEventTime);
    case 2:
      return mapBaseballEvent(event);
    case 3:
      return mapBasketballEvent(event, shortEventTime);
    case 4:
      return mapTennisEvent(event);
    case 5:
      return mapIceHockeyEvent(event, shortEventTime);
    case 6:
      return mapVolleyballEvent(event);
    case 7:
      return mapHandballEvent(event, shortEventTime);
    case 8:
      return mapTableTennisEvent(event);
    case 9:
      return mapBadmintonEvent(event);
    case 10:
      return mapFutsalEvent(event, shortEventTime);
    case 11:
      return mapAmericanFootballEvent(event, shortEventTime);
    case 12:
      return mapRugbyEvent(event, shortEventTime);
    case 13:
      return mapBeachVolleyEvent(event);
    case 14:
      return mapAussieRulesEvent(event, shortEventTime);
    case 15:
      return mapBasketball3x3Event(event, shortEventTime);
    case 16:
      return mapBowlsEvent(event);
    case 17:
      return mapDartsEvent(event);
    case 18:
      return mapFieldHockeyEvent(event);
    case 19:
      return mapSnookerEvent(event);
    case 20:
      return mapWaterpoloEvent(event);
    case 21:
      return mapMMAEvent(event);
    case 22:
      return mapSquashEvent(event);
    case 23:
      return mapPadelEvent(event);
    case 24:
      return mapKabaddiEvent(event);
    case 25:
      return mapESoccerEvent(event, shortEventTime);
    case 26:
      return mapEBasketballEvent(event, shortEventTime);
    case 27:
      return mapCounterStrikeEvent(event);
    case 28:
      return mapDota2Event(event);
    case 29:
      return mapLeagueOfLegendsEvent(event);
    case 30:
      return mapCricketEvent(event);
    case 31:
      return mapAlpineSkiingEvent(event);
    case 32:
      return mapBiathlonEvent(event);
    case 33:
      return mapFormula1Event(event);
    case 34:
      return mapGolfEvent(event);
    case 35:
      return mapMotoGPEvent(event);
    case 36:
      return mapSkiJumpingEvent(event);
    case 37:
      return mapNascarEvent(event);
    default:
      return null;
  }
};

export function formatLiveMetadata(events) {
  const betslipStore = useBetslipStore();
  const store = useStore();

  const betslip = betslipStore.getCurrentBetslip();
  if (!betslip) return;

  const collections = ['events', 'boostedBetslipsEvents', 'promotedEvents'];

  for (const event of events) {
    const mappedEvent = mapEventMetadata(event);
    if (!mappedEvent) continue;

    for (const collection of collections) {
      if (!store[collection]?.has(event.id)) continue;

      store[collection].get(event.id).metadata = mappedEvent;
    }

    if (store.betDetails) {
      store.betDetailsSelections.forEach((value, key) => {
        if (event.id === +key) value.metadata = mappedEvent;
      });
    }

    if (store.eventviewId === event.id && store.eventviewEvent?.id)
      store.eventviewEvent.metadata = mappedEvent;

    if (!betslipStore.selectionsByEvent.includes(`${event.id}`)) continue;
    for (const selection of Object.keys(betslip.selectionsData)) {
      const [eventId] = selection.split('/');

      if (+eventId === event.id)
        betslip['selectionsData'][selection] = {
          ...betslip['selectionsData'][selection],
          metadata: mappedEvent,
        };
    }
  }
}
