<script setup>
import { computed } from 'vue';

const props = defineProps({
  block: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  locked: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  backgroundColor: String,
  textColor: String,
  icon: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: [String],
    default: 'default',
    validator(value) {
      return [
        'default',
        'primary',
        'secondary',
        'accent',
        'ghost',
        'negative',
        'positive',
        'neutral',
        'inverted',
        'inverted-selected',
        'dark',
        'flat',
      ].includes(value);
    },
  },
  size: {
    type: String,
    default: 'md',
    validator(value) {
      return ['sm', 'md', 'lg'].includes(value);
    },
  },
});

const emit = defineEmits(['click']);

const classes = computed(() => ({
  'btn--block': props.block,
  'btn--pill': props.pill,
  'btn--disabled': props.disabled,
  'btn--locked': props.locked,
  'btn--icon': props.icon,
  [`btn--${props.variant}`]: props.variant,
  [`btn--${props.size}`]: true,
  'btn--active': props.active && !props.disabled,
}));

const styles = computed(() => ({
  backgroundColor: props.backgroundColor,
  color: props.textColor,
}));
</script>

<template>
  <button
    :class="['btn', classes]"
    :style="{ ...styles }"
    v-bind="$attrs"
    @click.stop="emit('click', $event)">
    <slot />
  </button>
</template>

<style lang="scss">
:root {
  --btn-bg: var(--button);
  --btn-text-color: var(--text-primary-1);
  --btn-locked-bg: var(--locked-outcome-bg);
  --btn-secondary-color: var(--secondary);
  --btn-primary-bg: var(--brand);
  --btn-primary-text: var(--text-delta);
  --btn-secondary-bg: var(--btn-accent-bg);
  --btn-secondary-active-text: var(--btn-accent-text);
  --btn-accent-bg: var(--primary);
  --btn-accent-text: var(--text-secondary-1);
  --btn-positive-bg: var(--success);
  --btn-positive-text: hsl(0, 0%, 100%);
  --btn-negative-bg: var(--error);
  --btn-negative-text: hsl(0, 0%, 100%);
  --btn-neutral-bg: var(--warning);
  --btn-neutral-text: #000;
  --btn-active-text: var(--btn-accent-text);
  --btn-active-background: var(--btn-accent-bg);
  --btn-inverted-selected-border: var(--primary);

  &[data-theme='dark'],
  &[data-theme='obsidian_blue'] {
    --btn-bg-dark: rgba(0, 0, 0, 0.2);
    --btn-bg-hover: hsla(0, 0%, 100%, 0.08);
    --btn-bg-pressed: hsla(0, 0%, 0%, 0.16);
    --btn-inverted-border: var(--btn-bg);
    --btn-flat-border: var(--btn-bg);
  }

  &[data-theme='light'] {
    --btn-bg-hover: hsla(0, 0%, 0%, 0.1);
    --btn-bg-pressed: hsla(0, 0%, 0%, 0.25);
    --btn-inverted-border: var(--button-stroke);
    --btn-flat-border: var(--input-stroke);
  }
}

.btn {
  position: relative;
  width: fit-content;
  height: var(--btn-height);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  color: var(--btn-text-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--button-stroke);
  background: var(--btn-bg);
  padding: var(--btn-padding);
  border-radius: var(--border-radius-xs);
  cursor: pointer;
  isolation: isolate;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    border-radius: inherit;
    backface-visibility: hidden;
    z-index: -1;
  }

  @media (hover: hover) {
    &:hover::before {
      background-color: var(--btn-bg-hover);
      opacity: 1;
    }
  }

  .icon {
    color: inherit;
  }

  &.btn--dark {
    color: var(--btn-primary-text);
    background: var(--btn-bg-dark);
    cursor: default;

    @media (hover: hover) {
      &:hover::before {
        pointer-events: none;
        cursor: default;
        opacity: 0;
      }
    }
  }

  &.btn--ghost {
    background: transparent;
    border: none;
    color: var(--btn-text-color);
  }

  &.btn--primary {
    color: var(--btn-primary-text);
    background: var(--btn-primary-bg);
    border: none;
  }

  &.btn--secondary {
    color: var(--btn-secondary-color);
    background: var(--btn-secondary-bg);
  }

  &.btn--accent {
    color: var(--btn-accent-text);
    background: var(--btn-accent-bg);
    border: none;
  }

  &.btn--positive {
    color: var(--btn-positive-text);
    background: var(--btn-positive-bg);
  }

  &.btn--negative {
    color: var(--btn-negative-text);
    background: var(--btn-negative-bg);
  }

  &.btn--neutral {
    color: var(--btn-neutral-text);
    background: var(--btn-neutral-bg);
  }

  &.btn--lg {
    font-size: var(--font-medium);

    & > * {
      font-size: var(--font-medium);
    }
  }

  &.btn--sm {
    font-size: var(--font-small);

    & > * {
      font-size: var(--font-small);
    }
  }

  &.btn--block {
    display: flex;
    width: 100%;
  }

  &.btn--pill {
    // arbitrary number bigger than button height gives pill shape
    border-radius: 20rem;
  }

  &.btn--icon {
    padding: 0.5rem;
    background-color: transparent;

    &::before {
      display: none;
    }
  }

  &.btn--disabled {
    background-color: var(--btn-locked-bg);
    pointer-events: none;
  }

  &.btn--locked {
    background-color: var(--btn-locked-bg);
  }

  &.btn--active {
    color: var(--btn-active-text);
    background: var(--btn-active-background);
  }

  &.btn--secondary.btn--active {
    color: var(--btn-secondary-active-text);
    background: var(--btn-secondary-active-background);
  }

  &.btn--inverted {
    background: transparent;
    border: 1px solid var(--btn-inverted-border);

    &.btn--selected {
      color: var(--btn-accent-bg);
      border-color: var(--btn-inverted-selected-border);

      * {
        color: var(--btn-accent-bg);
      }
    }
  }

  &.btn--inverted-selected {
    background: transparent;
    border: 1px solid var(--btn-inverted-selected-border);
    color: var(--btn-accent-bg);

    * {
      color: var(--btn-accent-bg);
    }
  }

  &.btn--flat {
    border-radius: 0;
    background: none;
    border: none;
    border-bottom: 1px solid var(--btn-flat-border);
    justify-content: left;
  }
}
</style>
