export default function loadSportradarTheming(theme) {
  const styleHref = `${import.meta.env.VITE_SERVERLESS_FUNCTIONS}/getWidgetTheming?tenantName=${tenantName}&tenantTheme=${theme}`;

  const existingEl = document.querySelector('link[id=SR_THEMING]');
  if (existingEl) {
    existingEl.href = styleHref;
    return;
  }

  const linkElement = document.createElement('link');
  linkElement.rel = 'stylesheet';
  linkElement.type = 'text/css';
  linkElement.id = 'SR_THEMING';
  linkElement.href = styleHref;
  document.head.appendChild(linkElement);
}
