import { io } from 'socket.io-client';
import { monotonicFactory } from 'ulidx';

import { useBetslipStore } from '@/stores/BetslipStore';

const ulid = monotonicFactory();

let socket;
export function establishBetslipDistributionSocketConnection(punter, forceConnection = false) {
  const betslipStore = useBetslipStore();

  const {
    setBetProcessing,
    displayBetRejectedReasons,
    handleBetslipAfterPlacedBet,
    displayMultipleRejectedBetsMessage,
    handleAlternativeStakes,
    updatePendingPlacedBets,
    getCurrentBetslip,
  } = betslipStore;

  if (socket?.connected) {
    if (!forceConnection) return;
    socket.disconnect();
    socket = null;
  }

  socket = io(import.meta.env.VITE_BETSLIP_DISTRIBUTION, {
    path: '/client/distribution/sio',
    query: {
      playerId: punter.id,
    },
    auth: {
      token: punterToken,
    },
    transports: ['websocket'],
  });

  socket.on('placementResult', (rawData) => {
    if (!betslipStore.betProcessing) return;

    setBetProcessing(false);

    const data = JSON.parse(rawData);
    const acceptedBets = [];
    const rejectedBets = [];
    let acceptedBetsCount = 0;
    let rejectedBetsCount = 0;

    updatePendingPlacedBets({ id: data.reqUuid, value: false });

    data.bets.forEach((bet) => {
      if (bet.status === 'PLACED') {
        acceptedBetsCount += 1;
        acceptedBets.push(bet);
      }

      if (bet.status === 'REJECTED') {
        rejectedBetsCount += 1;
        rejectedBets.push(bet);
      }
    });

    if (acceptedBetsCount) {
      handleBetslipAfterPlacedBet({
        acceptedBets,
        rejectedBetsCount,
        betslipToClear: betslipStore.betslipsToClear.get(data.reqUuid),
      });

      emitter.emit('fetch-wallet');
      betslipStore.betslipsToClear.delete(data.reqUuid);
    }

    if (!rejectedBetsCount) return;

    if (betslipStore.selectedTicketType === 'single') {
      const betslip = getCurrentBetslip();
      data.bets.forEach((bet) => {
        const clientHash = bet.hashes.find(({ type }) => type === 'CLIENT');
        for (const key of Object.keys(betslip.selectionsData)) {
          const selection = betslip.selectionsData[key];

          if (clientHash && clientHash.value === selection.hash) {
            selection.previousHash = selection.hash;
            selection.hash = ulid(Date.now());
          }
        }
      });
    }

    handleAlternativeStakes(data.bets, data.betslipId);

    if (rejectedBetsCount === 1) {
      const rejectedReasons = rejectedBets[0].reasons?.reduce((acc, { reason }) => {
        return acc.some((existingReason) => existingReason === reason) ? acc : [...acc, reason];
      }, []);
      displayBetRejectedReasons(rejectedReasons);
      return;
    }

    displayMultipleRejectedBetsMessage({
      showRejectedBetsCount: !!acceptedBetsCount,
      rejectedBetsCount,
    });
  });
}

export function disconnectFromBetslipDistributionSocketConnection() {
  if (socket?.id) {
    socket.disconnect();
    socket = null;
  }
}
