<template>
  <div class="bet-details-body">
    <div class="bet-details-body__selections">
      <div
        v-for="[id, selection] in store.betDetailsSelections"
        :key="id"
        class="bet-details-body__selection"
        @click="onSelectionClick(selection)">
        <div class="bet-details-body__selection-top">
          <div
            class="bet-details-body__selection-date"
            :class="{ 'bet-details-body__selection-date--highlighted': selection.metadata }">
            <span
              v-if="selection?.detailedPlayStatus == 7"
              class="time-badge">
              {{ store.getTranslation('live_metadata.general.status_interrupted') }}
            </span>
            <span
              v-else-if="selection?.detailedPlayStatus == 8"
              class="time-badge">
              {{ store.getTranslation('live_metadata.general.status_suspended') }}
            </span>
            <template v-else-if="selection.metadata">
              <Icon icon="n-i-live-b" />
              <span v-if="selection.metadata.displayEventStatus">{{
                selection.metadata.eventStatus
              }}</span>
              <span v-if="selection.metadata.displayEventStatus && selection.metadata?.eventTime">
                -
              </span>
              <span v-if="selection.metadata.eventTime">{{ selection.metadata.eventTime }} </span>
              <span v-if="selection.metadata.eventExtraTime"
                >+{{ selection.metadata.eventExtraTime }}
              </span>
            </template>

            <span v-else>{{ formatSelectionDate(selection.event.startAt) }}</span>
          </div>
          <div
            v-if="selection.banker && isSystemBet"
            class="bet-details-body__selection-banker">
            {{ store.getTranslation('banker') }}
          </div>

          <div class="spacer"></div>

          <StatusBadge
            :status="getSelectionResolutionStatusLabel(selection.resolutionStatuses)"
            :type="getSelectionResolutionStatus(selection.resolutionStatuses)"
            size="small" />
        </div>

        <div class="bet-details-body__selection-match">
          <div class="teams">
            <div
              class="team1"
              :class="{ 'team1--server': selection.metadata?.server?.home }">
              <span
                v-if="selection.metadata?.server?.home"
                class="teams-server-indicator"></span>
              <span class="team-name">
                {{ getTeamNames(selection.event.name)[0] }}
              </span>
              <span v-if="showResults">
                <!-- TODO: Display Result, once we start receiving it via API. -->
              </span>
            </div>
            <div
              class="team2"
              :class="{ 'team2--server': selection.metadata?.server?.away }">
              <span
                v-if="selection.metadata?.server?.away"
                class="teams-server-indicator"></span>
              <span class="team-name">
                {{ getTeamNames(selection.event.name)[1] }}
              </span>
            </div>
          </div>
          <div
            v-if="selection.metadata"
            class="results">
            <template
              v-for="(result, idx) in formatResults(selection.metadata)"
              :key="idx">
              <div
                v-if="result"
                class="results__score">
                <span>{{ result.home }}</span>
                <span>{{ result.away }}</span>
              </div>
            </template>
          </div>
        </div>

        <template v-if="selection.eventMarketOutcomes.length > 1">
          <div class="bet-details-body__selection-market">
            {{ store.getTranslation('bet_builder') }}
          </div>

          <div class="bet-details-body__selection-outcome">
            <div
              class="bet-details-body__selection-outcome-name bet-details-body__selection-outcome-name--highlight">
              {{ store.getTranslation('bet_builder') }}
            </div>
            <div class="bet-details-body__selection-outcome-odd">
              <span
                v-if="shouldShowSettleOdds(selection.resolutionStatuses)"
                class="line-through"
                >{{ getRequestedOdds(selection.odds) }}</span
              >
              <span>{{
                getDisplayOdds({
                  odds: selection.odds,
                  resolutionStatuses: selection.resolutionStatuses,
                })
              }}</span>
            </div>
          </div>
        </template>

        <template
          v-for="eventMarketOutcomeData in selection.eventMarketOutcomes"
          :key="eventMarketOutcomeData.id">
          <div class="bet-details-body__selection-market">
            {{ eventMarketOutcomeData.eventMarket.name }}
          </div>

          <div class="bet-details-body__selection-outcome">
            <div class="bet-details-body__selection-outcome-name">
              {{ eventMarketOutcomeData.name }}
            </div>
            <div class="bet-details-body__selection-outcome-odd">
              <template v-if="selection.eventMarketOutcomes.length === 1">
                <span
                  v-if="shouldShowSettleOdds(selection.resolutionStatuses)"
                  class="line-through"
                  >{{ getRequestedOdds(selection.odds) }}</span
                >
                <span>{{
                  getDisplayOdds({
                    odds: selection.odds,
                    resolutionStatuses: selection.resolutionStatuses,
                  })
                }}</span>
              </template>

              <template v-else>
                <StatusBadge
                  :status="
                    getSelectionResolutionStatusLabel(eventMarketOutcomeData.resolutionStatuses)
                  "
                  :type="getSelectionResolutionStatus(eventMarketOutcomeData.resolutionStatuses)"
                  size="small" />
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div
      class="bet-details-body__meta"
      @click="metaExpanded = !metaExpanded">
      <div class="bet-details-body__meta-row bet-details-body__meta-row--big">
        <div class="bet-details-body__meta-label">
          {{ store.getTranslation(desiredProfit ? 'desired_profit' : 'payin') }}
        </div>

        <Icon
          v-if="showExpandIcon"
          :icon="`n-i-arrow-${!metaExpanded ? 'down' : 'up'}-a`" />

        <div class="bet-details-body__meta-value">
          {{
            desiredProfit
              ? desiredProfit
              : formatNumberWithCurrency(bet.payin.stake.amount, bet.currency)
          }}
        </div>
      </div>

      <div
        class="bet-details-body__meta-row bet-details-body__meta-row--primary bet-details-body__meta-row--highlight">
        <div class="bet-details-body__meta-label">
          {{ payoutLabel }}
        </div>
        <div class="bet-details-body__meta-value">
          {{ formatNumberWithCurrency(winningsToDisplay.total, bet.currency) }}
        </div>
      </div>

      <div
        v-if="bonusAmount.payin"
        class="bet-details-body__meta-row bet-details-body__meta-row--highlight">
        <div class="bet-details-body__meta-label">
          {{ store.getTranslation('bet_bonus.payin') }}
          <!-- ({{ bet.betBonus?.percentage }}%) -->
        </div>
        <div class="bet-details-body__meta-value">
          {{ formatNumberWithCurrency(bonusAmount.payin, bet.currency) }}
        </div>
      </div>

      <div
        v-if="bonusAmount.winnings"
        class="bet-details-body__meta-row bet-details-body__meta-row--highlight">
        <div class="bet-details-body__meta-label">
          {{ store.getTranslation('bet_bonus.winnings') }}
          <!-- ({{ bet.betBonus?.percentage }}%) -->
        </div>
        <div class="bet-details-body__meta-value">
          {{ formatNumberWithCurrency(bonusAmount.winnings, bet.currency) }}
        </div>
      </div>

      <Transition
        name="slide-up-fade"
        @enter="enter"
        @leave="leave"
        @after-enter="afterEnter"
        @before-enter="beforeEnter"
        @before-leave="beforeLeave">
        <div v-show="metaExpanded">
          <div
            v-if="minWinnings && isSystemBet"
            class="bet-details-body__meta-row">
            <div class="bet-details-body__meta-label">{{ store.getTranslation('min_payout') }}</div>
            <div class="bet-details-body__meta-value">
              {{ minWinnings.total }}
            </div>
          </div>

          <div
            v-if="totalOdds && !isSystemBet"
            class="bet-details-body__meta-row">
            <div class="bet-details-body__meta-label">{{ store.getTranslation('total_odds') }}</div>
            <div class="bet-details-body__meta-value">
              {{ totalOdds }}
            </div>
          </div>

          <div
            v-if="isSystemBet"
            class="bet-details-body__meta-row">
            <div class="bet-details-body__meta-label">
              {{ store.getTranslation('total_combinations') }}
            </div>
            <div class="bet-details-body__meta-value">{{ totalCombinations }}</div>
          </div>

          <div
            v-if="bet.payin.taxes[0] && bet.payin.taxes[0].amount"
            class="bet-details-body__meta-row">
            <div class="bet-details-body__meta-label">{{ store.getTranslation('payin_tax') }}</div>
            <div class="bet-details-body__meta-value">
              {{ bet.payin.taxes[0].amount.toFixed(2) }}
            </div>
          </div>

          <div
            v-if="winningsToDisplay.taxes[0] && winningsToDisplay.taxes[0].amount"
            class="bet-details-body__meta-row">
            <div class="bet-details-body__meta-label">{{ store.getTranslation('payout_tax') }}</div>
            <div class="bet-details-body__meta-value">
              {{ winningsToDisplay.taxes[0].amount.toFixed(2) }}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup>
import { toRef, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import useSlideTransition from '@/composables/transitions/useSlideTransition';
import useBetDetails from '@/composables/bet-list/useBetDetails';
import StatusBadge from '@/components/common/StatusBadge.vue';
import Icon from '@/components/common/Icon.vue';
import { useStore } from '@/stores/store';
import { formatNumberWithCurrency } from '@/utils/helpers';

const props = defineProps({
  bet: {
    type: Object,
    required: true,
    default: () => {},
  },
  availableEvents: {
    type: Array,
    required: true,
    default: () => [],
  },
  showStatus: {
    type: Boolean,
    required: false,
    default: false,
  },
  showResults: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const router = useRouter();
const store = useStore();
const { beforeEnter, beforeLeave, afterEnter, enter, leave } = useSlideTransition();

const betRef = toRef(props, 'bet');
const {
  totalOdds,
  isSystemBet,
  totalCombinations,
  formatSelectionDate,
  winningsToDisplay,
  minWinnings,
  bonusAmount,
  payoutLabel,
  getSelectionResolutionStatus,
  getSelectionResolutionStatusLabel,
  getDisplayOdds,
  getRequestedOdds,
  shouldShowSettleOdds,
  desiredProfit,
} = useBetDetails(betRef);

const metaExpanded = ref(false);

const showExpandIcon = computed(() => {
  return (
    totalOdds.value ||
    isSystemBet.value ||
    props.bet.payin.taxes[0]?.amount ||
    winningsToDisplay.value?.taxes[0]?.amount
  );
});

// TODO: This will be refined, once we get proper Bets API.
const getTeamNames = (eventName) => eventName.split(' vs. ');

const formatResults = ({
  currentScoreInSets,
  currentScoreInCurrentSet,
  currentGameScore,
  hideCurrentGameScore,
}) => {
  return [
    currentScoreInSets ?? null,
    currentScoreInCurrentSet ?? null,
    !hideCurrentGameScore ? (currentGameScore ?? null) : null,
  ];
};

const onSelectionClick = ({ event }) => {
  if (!props.availableEvents.includes(+event.syntheticId)) return;

  const cRoute = router.currentRoute.value;
  let { path } = cRoute;

  path = path.replace('/v_last-tickets', '');
  path = path === '/' ? `e_${event.syntheticId}` : `${path}/e_${event.syntheticId}`;

  router.push({
    path,
    query: cRoute.query,
  });
};
</script>

<style lang="scss">
:root {
  --bet-details-body-badge-color: var(--text-primary-1);
  --bet-details-body-date-color: var(--text-primary-2);
  --bet-details-body-selection-bg-color: var(--card-background);
  --bet-details-body-selection-color: var(--text-primary-1);
  --bet-details-body-selection-date-color: var(--text-primary-2);
  --bet-details-body-selection-date-highlight-color: var(--text-secondary-1);
  --bet-details-body-selection-date-highlight-bg-color: var(--primary);
  --bet-details-body-selection-outcome-name-highlight-color: var(--primary);
  --bet-details-body-selection-outcome-area-bg-color: var(--card-section-2);
  --bet-details-body-selection-result-highlight: var(--primary);
  --bet-details-body-combinations-bg-color: var(--background-1);
  --bet-details-body-combinations-color: var(--text-primary-2);
  --bet-details-body-combinations-table-bg-color: var(--background-1);
  --bet-details-body-combinations-table-color: var(--text-primary-2);
  --bet-details-body-meta-bg-color: var(--card-background);
  --bet-details-body-meta-color: var(--text-primary-2);
  --bet-details-body-meta-primary-row-color: var(--text-primary-1);
  --bet-details-body-meta-highlighted-row-color: var(--primary);
  --bet-details-server-indicator-color: var(--indicator-color);
}
</style>

<style scoped lang="scss">
.bet-details-body {
  &__selections {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    margin-bottom: 1px;
  }

  &__selection {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
    padding: 0.5rem;
    background-color: var(--bet-details-body-selection-bg-color);
    color: var(--bet-details-body-selection-color);
    border-radius: var(--border-radius-xs);

    .spacer {
      flex: 1;
    }

    &-top {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    &-market {
      font-size: 0.875rem;
      font-weight: 400;
      color: var(--bet-details-body-selection-date-color);
    }

    &-date,
    &-banker {
      height: 1.3rem;
      display: flex;
      align-items: center;
      gap: var(--gap-4);
      padding: 0 var(--gap-4);
      border: 1px solid var(--event-indicator-stroke);
      border-radius: var(--border-radius-xs);
      font-size: var(--font-small);
      background-color: var(--event-indicator-bg-color);
      font-weight: 500;
      color: var(--event-indicator-color);
    }

    &-date--highlighted {
      background-color: var(--bet-details-body-selection-date-highlight-bg-color);
      color: var(--bet-details-body-selection-date-highlight-color);
    }

    &-banker {
      text-transform: uppercase;
    }

    &-match {
      display: flex;
      align-items: center;
    }

    .teams {
      flex: 1;
    }

    .teams-server-indicator {
      width: 4px;
      background: var(--bet-details-server-indicator-color);
      height: 12px;
      border-radius: 0 2px 2px 0;
    }

    .team1,
    .team2 {
      font-size: 0.875rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: calc(1rem - 0.25rem);

      .team-name {
        flex: 1;
      }

      &--server {
        margin-left: -1rem;
      }
    }

    .teams,
    .results {
      line-height: 20px;
    }

    .results {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      &__score {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        text-align: center;

        &:last-child {
          color: var(--bet-details-body-selection-result-highlight);
        }
      }
    }

    &-outcome {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      background-color: var(--bet-details-body-selection-outcome-area-bg-color);
      min-height: 2rem;
      padding: 0.5rem;
      border-radius: var(--border-radius-xs);

      &-name {
        font-weight: 400;
        flex: 1;
        word-break: break-word;

        &--highlight {
          color: var(--bet-details-body-selection-outcome-name-highlight-color);
        }
      }

      &-odd {
        display: flex;
        align-items: center;
        gap: var(--gap-base);
        font-weight: 700;

        .line-through {
          color: var(--text-primary-2);
          text-decoration: line-through;
          font-size: 0.625rem;
        }
      }
    }
  }

  &__combinations {
    margin-bottom: 0.0625rem;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.7rem 1rem;
      background-color: var(--bet-details-body-combinations-bg-color);
      color: var(--bet-details-body-combinations-color);
      height: 2.5rem;
      margin-bottom: 0.0625rem;
      border-radius: var(--border-radius);
    }

    &-label {
      font-size: 0.875rem;
      font-weight: 400;
    }

    &-toggle-icon {
      width: 1rem;
      height: 1rem;
    }
  }

  .combinations-table {
    padding: 0.5rem 1rem;
    background-color: var(--bet-details-body-combinations-table-bg-color);
    color: var(--bet-details-body-combinations-table-color);
    border-radius: var(--border-radius);

    &__header,
    &__row {
      display: flex;
      align-items: center;
      gap: 32px;
    }

    &__header {
      font-size: 0.625rem;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0.25rem;
    }

    &__row {
      font-size: 0.75rem;
      font-weight: 400;
      padding-top: 0.25rem;
    }

    &__column {
      flex: 1;
    }
  }

  &__meta {
    background-color: var(--bet-details-body-meta-bg-color);
    color: var(--bet-details-body-meta-color);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.13rem;
    border-radius: var(--border-radius-xs);

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.75rem;
      text-transform: capitalize;
      line-height: 1.125rem;

      &--big {
        font-size: 0.875rem;
      }

      &--primary {
        font-size: 0.875rem;
        color: var(--bet-details-body-meta-primary-row-color);
      }

      &--highlight {
        color: var(--bet-details-body-meta-highlighted-row-color);
      }
    }

    &-label,
    &-value {
      flex: 1;
    }

    &-value {
      text-align: right;
    }
  }
}
</style>
