import { defineStore } from 'pinia';
import { ref, computed, shallowRef } from 'vue';
import { isEmpty, isBoolean, orderBy } from 'lodash-es';
import { useRouter } from 'vue-router';
import { monotonicFactory } from 'ulidx';

import getSymbolFromCurrency from 'currency-symbol-map';

import { formatOdd } from '@nsftx/sports-client-odds-conversion';
import { useStore } from '@/stores/store';
import useSupplemental from '@/composables/supplemental/useSupplemental.js';
import { placeBet, checkBetslip, acceptAlternativeStakeBet } from '@/api/betslip';
import { getBetBuilderCalculations } from '@/api/custom-bet';
import {
  subscribeEventsOnMetadataChanges,
  subscribeEventsOnOfferChanges,
} from '@/services/sockets/offer-distribution';
import { sendCalculationWSMessage } from '@/services/sockets/calculationSocket';
import { formatBerryBetPlacement } from '@/services/formatters/berryFormatter';
import {
  formatThirdPartyBet,
  formatBetForBetslipPlacement,
  formatThirdPartySelection,
} from '@/services/formatters/betslipFormatter';
import { isMobile } from '@/utils/helpers';
import { getItemFromStorage, setItemToStorage } from '@/services/storage';

import BetslipWorker from '@/services/betslip-worker/index?worker';
import { convertToIntegerOdds, convertDecimalOddsToInteger } from '../utils/helpers';
import { getCustomBets } from '@/api/custom-bet';
import { removeItemFromStorage } from '@/services/storage';
import { sortAndFilterEventMarketOutcomes } from '@/services/offer-adapter/index.js';

const betslipWorker = new BetslipWorker();
const ulid = monotonicFactory();

export const useBetslipStore = defineStore('BetslipStore', () => {
  const router = useRouter();

  const store = useStore();

  const betslips = ref(new Map());
  const selectedBetslipIndex = ref(0);

  const { getSupplemental, getMarketSupplementalName, getMarketOutcomeSupplementalName } =
    useSupplemental('betslip');

  const pascalTriagle = shallowRef([]);

  const selectionsByEvent = computed(() => {
    const betslip = getCurrentBetslip();

    return (
      betslip?.selectionsList.map((selection) => {
        const [event] = selection.split('/');
        return event;
      }) ?? []
    );
  });

  const calculationWinnings = ref(null);

  const limits = ref({});
  const breachedLimits = ref([]);

  const exchangedIdsCache = ref({});

  const ticketOptions = getItemFromStorage('ticketOptions');

  const selectedTicketType = ref(ticketOptions?.type ?? 'combo');
  const selectedBetType = ref(selectedTicketType.value);

  const alternativeStakeData = ref({});

  const betProcessing = ref(false);
  function setBetProcessing(value) {
    betProcessing.value = value;
  }

  const pendingPlacedBets = ref({});

  /**
   * Computed
   */

  const activeSelectionsCount = computed(() => {
    const betslip = getCurrentBetslip();

    return Object.values(betslip?.selectionsData ?? {}).filter(
      (selection) =>
        [...selection.markets]?.filter(([, { active }]) => active).length > 1 || selection.active,
    ).length;
  });

  const availableSystems = computed(() => {
    const betslip = getCurrentBetslip();

    return Object.values(betslip?.selectionsData ?? {})?.filter((selection) => {
      return !selection.banker && selection.active;
    });
  });

  const totalSelectionsOdds = computed(() => {
    const betslip = getCurrentBetslip();

    const totalOdds = Object.values(betslip?.selectionsData ?? {})
      .reduce(
        (acc, selection) => {
          return [...selection.markets].filter(([, { active }]) => active).length > 1 ||
            selection.active
            ? acc * +formatOdd(selection.odds, 'decimal')
            : acc;
        },
        activeSelectionsCount.value ? 1 : 0,
      )
      .toFixed(2);

    if (store.offerView === 'indonesian') {
      return formatOdd(
        +totalOdds,
        selectedTicketType.value === 'single' ? 'indonesian' : 'combo',
        1,
      );
    }

    return formatOdd(+totalOdds, store.oddFormat, 1);
  });

  const splitStakePerSelection = computed(() => {
    const betslip = getCurrentBetslip();

    return parseFloat((parseFloat(betslip.stake) / activeSelectionsCount.value || 1).toFixed(2));
  });

  const totalSelectionsStake = computed(() => {
    if (!activeSelectionsCount.value) return 0;

    const betslip = getCurrentBetslip();
    return Object.values(betslip?.selectionsData ?? {})
      .reduce((acc, selection) => acc + parseFloat(selection.stake || 0), 0)
      .toFixed(2);
  });

  const selectionHasSingleStakeSet = computed(() => {
    const betslip = getCurrentBetslip();
    if (!betslip) return false;

    return betslip.selectionsList.find((id) => betslip.selectionsData[id]?.stake);
  });

  const totalSystemCombinations = computed(() => {
    const betslip = getCurrentBetslip();
    if (!betslip) return 0;

    return Object.values(betslip.systems).reduce((acc, n) => acc + n.combinations, 0);
  });

  const defaultStake = computed(() => {
    const FALLBACK_STAKE = 2;

    return parseFloat(
      store.config?.betslip?.stake?.[store.currency || window.currency] ??
        store?.config?.betslip?.stake?.default ??
        FALLBACK_STAKE,
    ).toFixed(2);
  });

  /**
   * Actions
   */

  function setSelectedBetslipIndex(value) {
    selectedBetslipIndex.value = value;
  }

  function removeSelection(id) {
    const selectedBetslip = getCurrentBetslip();
    if (!selectedBetslip?.selectionsData[id]) return;

    store.setActiveTicketTab('betslip');

    delete selectedBetslip?.selectionsData[id];

    selectedBetslip.selectionsList = selectedBetslip?.selectionsList.filter(
      (selection) => selection !== id,
    );

    clearSystems();

    if (
      !selectedBetslip?.selectionsList.length &&
      selectedTicketType.value !== 'single' &&
      store.offerView !== 'indonesian'
    )
      selectedTicketType.value = 'combo';

    alternativeStakeData.value.enabled = false;
  }

  function addSelection(id, data = {}) {
    const selectedBetslip = getCurrentBetslip();

    if (!selectedBetslip) return;

    store.setActiveTicketTab('betslip');
    selectedBetslip.selectionsData[id] = data;

    if (store.offerView === 'indonesian') {
      let someBetslipHasBets = false;

      betslips.value.forEach((betslip) => {
        if (betslip.selectionsList.length) {
          someBetslipHasBets = betslip.selectionsList.length;
          return;
        }
      });

      if (
        !someBetslipHasBets &&
        indonesianOfferType.value === 'single' &&
        selectedTicketType.value !== 'single'
      ) {
        selectedTicketType.value = 'single';
      }

      if (selectedTicketType.value === 'single') {
        const odds = convertToIntegerOdds(data.odds);

        if (odds > 2 && !selectedBetslip.stake) {
          updateStake(store.punterPreferences?.defaultPayment ?? defaultStake.value);
        } else if (odds <= 2 && selectedBetslip.stake) {
          updateStake((selectedBetslip.desiredProfit / (odds - 1)).toFixed(2));
        }
      }
    }

    if (!selectedBetslip.selectionsList.some((selection) => selection === id)) {
      selectedBetslip.selectionsList.push(id);
    }

    alternativeStakeData.value.enabled = false;
  }

  async function setBetBuilderSelectionOdds(id) {
    const betslip = getCurrentBetslip();
    if (!betslip?.selectionsData[id]) return;

    const isSelectionActive =
      Array.from(betslip.selectionsData[id].markets).filter(([, value]) => value.active).length > 1;

    if (!isSelectionActive) {
      betslip.selectionsData[id].odds = 0;
      return;
    }

    setBetslipLoading(true);

    const response = await getBetBuilderCalculations(
      betslip.selectionsData[id].eventId,
      store.getEventSelectionsForCalculation(betslip.selectionsData[id].eventId, id),
    );

    betslip.selectionsData[id] = {
      ...betslip.selectionsData[id],
      odds: (response?.odds ?? 1) * 10000,
    };

    setBetslipLoading(false);
  }

  async function toggleSelection({ id, outcomeId, marketId, value }) {
    const betslip = getCurrentBetslip();
    if (!betslip?.selectionsData[id] || !betslip.selectionsData[id]) return;

    if (betslip.selectionsData[id].type === 'bet_builder') {
      if (betslip.selectionsData[id].markets?.get(`${marketId}/${outcomeId}`)) {
        const isActive = isBoolean(value)
          ? value
          : !betslip.selectionsData[id].markets.get(`${marketId}/${outcomeId}`).active;

        betslip.selectionsData[id].markets.get(`${marketId}/${outcomeId}`).active = isActive;

        window.pushEventToGoogleTagManager(
          betslip.selectionsData[id].markets.get(`${marketId}/${outcomeId}`).active
            ? 'select_betslip_selection'
            : 'deselect_betslip_selection',
        );
      }
    } else {
      betslip.selectionsData[id].active = !betslip.selectionsData[id].active;
      window.pushEventToGoogleTagManager(
        betslip.selectionsData[id].active
          ? 'select_betslip_selection'
          : 'deselect_betslip_selection',
      );
    }

    clearSystems();
  }

  function validateBetslip() {
    const betslip = getCurrentBetslip();

    if (!betslip) return;

    sendBetslipWorkerMessage('validate_betslip', {
      winnings: calculationWinnings.value,
      payment: +betslip.stake,
      numberOfSelections: activeSelectionsCount.value,
      ticketType: selectedTicketType.value,
      systems: betslip.systems,
      selections: betslip.selectionsData,
    });
  }

  function updateBetCalculationData(data) {
    calculationWinnings.value = data.winnings.betslip.bets.reduce(
      (acc, n) => {
        acc.max += +n.pWinnings.max.total;
        acc.winningsBonus += n.pWinnings.max.bonuses.reduce((acc, n) => (acc += +n.amount), 0);
        acc.payinBonus += n.payin.bonuses.reduce((acc, n) => (acc += +n.amount), 0);

        if (acc.min > +n.pWinnings.min.total || !acc.min) acc.min = +n.pWinnings.min.total;
        return acc;
      },
      { max: 0, min: 0, winningsBonus: 0, payinBonus: 0 },
    );

    exchangedIdsCache.value = data.mappedIds;
    validateBetslip();
  }

  function updateStake(value) {
    alternativeStakeData.value.enabled = false;

    if (!value) emitter.emit('reset_predefined_stake_top_up');

    const betslip = getCurrentBetslip();
    if (!betslip) return;

    betslip.stake = value;
  }

  function updateDesiredProfit(value) {
    const betslip = getCurrentBetslip();

    if (!betslip) return;

    betslip.desiredProfit = value;
  }

  function clearSelectionsStake() {
    const betslip = getCurrentBetslip();
    betslip?.selectionsList.forEach((id) => delete betslip?.selectionsData[id].stake);
  }

  function updateSystem(data) {
    const betslip = getCurrentBetslip();
    if (!betslip) return;

    alternativeStakeData.value.enabled = false;

    betslip.systems[data.id] = {
      ...data,
    };

    requestBetCalculation();
    storeBetslipDataInLS();
  }

  function updateSystemStake(data) {
    const betslip = getCurrentBetslip();
    if (!betslip?.systems) return;

    alternativeStakeData.value.enabled = false;

    betslip.systems[data.id] = {
      ...data,
    };

    const totalStake = Object.values(betslip.systems).reduce(
      (acc, system) => acc + parseFloat(system.stake || 0),
      0,
    );

    updateStake(parseFloat(totalStake).toFixed(2));
  }

  function clearSystems() {
    const betslip = getCurrentBetslip();
    if (!betslip?.systems) return;

    betslip.systems = {};
  }

  function clearWinnings() {
    if (!calculationWinnings.value?.pWinnings) return;
    calculationWinnings.value.pWinnings.max.total = '0.00';
  }

  function checkForSystemCombinations() {
    if (!totalSystemCombinations.value && selectedTicketType.value === 'system') {
      store.addNotification({
        id: 'betslip_no_combinations',
        text: store.getTranslation('betslip_validation.bet_no_combinations'),
        type: 'warning',
        betslip: true,
      });
      return;
    }

    store.removeNotification('betslip_no_combinations');
  }

  function selectTicketType(value = 'combo') {
    if (value === selectedTicketType.value) return;

    const betslip = getCurrentBetslip();
    if (!betslip) return;

    alternativeStakeData.value.enabled = false;

    if (
      value !== 'combo' &&
      betslip.selectionsList.some((id) => betslip.selectionsData[id]?.type === 'bet_builder')
    ) {
      store.addNotification({
        timeout: 2000,
        text: store.getTranslation('bet_builder_error_ticket_type'),
        type: 'error',
      });
      return;
    }

    if (
      (indonesianOfferType.value === 'single' &&
        store.isBettingWithDesiredProfit &&
        !betslip?.desiredProfit) ||
      betslip.stake === null
    ) {
      betslip.stake = store.punterPreferences?.defaultPayment ?? defaultStake.value;
    }

    selectedTicketType.value = value;

    clearSystems();

    if (value !== 'single') {
      const uniqSelectionsByEvent = {};
      const uniqEvents = {};

      for (const selectionId in betslip.selectionsData) {
        const selection = betslip.selectionsData[selectionId];

        if (!selection.betBuilderBets && !uniqEvents[selection.eventId]) {
          uniqSelectionsByEvent[selectionId] = selection;
          uniqEvents[selection.eventId] = 1;
        } else if (selection.betBuilderBets) {
          uniqSelectionsByEvent[selectionId] = selection;
        }
      }

      betslip.selectionsData = uniqSelectionsByEvent;
      betslip.selectionsList = Object.keys(uniqSelectionsByEvent);
    }

    checkForSystemCombinations();
    if (betslips.value.size === 1) {
      requestBetCalculation();
    }
    storeBetslipDataInLS();
  }

  function removeSelectionsForSingleIndonesianTicketType(ticketType = selectedTicketType.value) {
    const betslip = getCurrentBetslip();

    if (
      !betslip ||
      !betslip.selectionsList.length ||
      ticketType !== 'single' ||
      store.offerView !== 'indonesian'
    ) {
      return;
    }

    const [firstSelection] = betslip.selectionsList;
    betslip.selectionsList = [firstSelection];
    betslip.selectionsData = { [firstSelection]: betslip.selectionsData[firstSelection] };
    const odds = convertToIntegerOdds(betslip.selectionsData[firstSelection].odds, 10000);
    if (odds <= 2) {
      updateStake(
        betslip.desiredProfit ? parseFloat(+betslip.desiredProfit / (odds - 1)).toFixed(2) : 0,
      );
    }
  }

  function shouldPreventCalculation() {
    const betslip = getCurrentBetslip();
    if (!betslip?.systems) return;

    if (selectedTicketType.value === 'system') {
      // No selected systems
      if (!availableSystems.value?.find((_, id) => betslip.systems[id]?.selected)) return;
    }

    return false;
  }

  function requestBetCalculation() {
    if (shouldPreventCalculation()) {
      validateBetslip();
      return;
    }

    const betslip = getCurrentBetslip();
    if (!betslip || !+betslip.stake) return;
    const data = formatBerryBetPlacement(
      {
        selections: betslip.selectionsData,
        stake: betslip.stake,
        cachedIds: exchangedIdsCache.value,
        selectedTicketType: selectedTicketType.value,
        systems: betslip.systems,
      },
      store.isBettingWithDesiredProfit,
    );

    if (data?.betslip?.bets?.length)
      sendCalculationWSMessage('calculation', {
        data,
      });
  }

  const betslipSave = ref({});

  function clearBetslip(selectedBetslip) {
    const betslip = selectedBetslip ? betslips.value.get(selectedBetslip) : getCurrentBetslip();
    if (!betslip) return;

    removeItemFromStorage(`betslip-${selectedBetslipIndex.value}`);

    betslipSave.value = {
      selectionsData: betslip.selectionsData,
      selectionsList: betslip.selectionsList,
      stake: betslip.stake,
      desiredProfit: betslip.desiredProfit,
      systems: betslip.systems,
      selectedTicketType: selectedTicketType.value,
      betslipIndex: selectedBetslipIndex.value,
    };

    const selectionsAsArr = Object.values(betslip.selectionsData).filter(
      (selection) => selection.type === 'bet_builder',
    );
    if (selectionsAsArr.length) {
      selectionsAsArr.forEach((selection) => {
        resetBetBuilderSelections(+selection.eventId);
      });
    }

    betslip.selectionsData = {};
    betslip.selectionsList = [];
    betslip.stake = store.punterPreferences?.defaultPayment ?? defaultStake.value;
    betslip.desiredProfit = null;
    calculationWinnings.value = null;

    let someBetslipHasBets = false;
    betslips.value.forEach((betslip) => {
      if (betslip.selectionsList.length) {
        someBetslipHasBets = betslip.selectionsList.length;
        return;
      }
    });

    selectedTicketType.value =
      store.config.betslip.numberOfBetslips === 1 || store.offerView !== 'indonesian'
        ? 'combo'
        : someBetslipHasBets
          ? selectedTicketType.value
          : indonesianOfferType.value;

    if (store.offerView === 'indonesian' && indonesianOfferType.value && !someBetslipHasBets) {
      setSelectedBetslipIndex(indonesianOfferType.value === 'single' ? 0 : 1);
    }
    clearSystems();
    clearWinnings();
  }

  function closeMobileBetslip() {
    router.push({
      path: store.route.path.replace('/v_betslip', '') || '/',
      query: store.route.query,
    });
  }

  function updatePreviousOddsRef(data = {}) {
    const betslip = getCurrentBetslip();
    if (betslip) return;

    Object.keys(data).forEach((key) => {
      if (betslip.selectionsData[key]) {
        betslip.selectionsData[key] = {
          ...betslip.selectionsData[key],
          ...data[key],
        };
      }
    });
  }

  const betslipsToClear = ref(new Map());

  function resetSelectionsDataHashes() {
    const betslip = getCurrentBetslip();

    for (const key of Object.keys(betslip.selectionsData)) {
      const selection = betslip.selectionsData[key];
      selection.previousHash = selection.hash;
      selection.hash = ulid(Date.now());
    }
  }

  async function payin() {
    const betslip = getCurrentBetslip();
    if (betProcessing.value || !betslip) return;
    setBetProcessing(true);

    const bet = formatBetForBetslipPlacement({
      selectionsData: betslip.selectionsData,
      stake: betslip.stake,
      selectedTicketType: selectedTicketType.value,
      systems: betslip.systems,
    });

    if (isEmpty(bet)) {
      setBetProcessing(false);
      return;
    }

    if (alternativeStakeData.value?.enabled) {
      bet.bets.forEach((bet) => {
        if (
          !(
            alternativeStakeData.value[bet.previousHash]?.betId || alternativeStakeData.value?.betId
          )
        ) {
          return;
        }

        bet.relation = {
          betId:
            alternativeStakeData.value[bet.previousHash]?.betId ?? alternativeStakeData.value.betId,
          type: 'ALT-STAKE',
        };

        if (store.isBettingWithDesiredProfit) {
          const stake = bet.payin.stake
            ? {
                stake: {
                  ...bet.payin.stake,
                  amount:
                    alternativeStakeData.value[bet.previousHash]?.amount ??
                    alternativeStakeData.value.amount,
                },
              }
            : {};

          bet.payin = {
            ...bet.payin,
            ...stake,
          };
        }

        delete bet.previousHash;
      });

      try {
        bet.bets = bet.bets.filter((bet) => bet.relation);
        await acceptAlternativeStakeBet({
          bet,
          ...alternativeStakeData.value,
          ...(alternativeStakeData.value[bet.previousHash]
            ? alternativeStakeData.value[bet.previousHash]
            : {}),
        });
      } catch (e) {
        resetSelectionsDataHashes();
        console.error('Error while accepting alternate stake.', e);
        setBetProcessing(false);
      }
    }

    const noRelationBets = bet.bets.reduce((acc, bet) => {
      const betFormatted = { ...bet };
      delete betFormatted.previousHash;

      return !betFormatted.relation ? [...acc, betFormatted] : acc;
    }, []);
    if (!noRelationBets.length) return;

    try {
      pendingPlacedBets.value[bet.reqUuid] = true;
      betslipsToClear.value.set(bet.reqUuid, `betslip-${selectedBetslipIndex.value}`);

      await placeBet({ ...bet, bets: noRelationBets });
      window.pushEventToGoogleTagManager('bet_placed', {
        requestUuid: bet.reqUuid,
      });

      let counter = 0;
      const interval = setInterval(async () => {
        counter += 1;

        if (counter > 5 || !betProcessing.value || !pendingPlacedBets.value[bet.reqUuid]) {
          clearInterval(interval);
          return;
        }

        try {
          const response = await checkBetslip(bet.reqUuid);

          const { bets } = response.data.betslip;
          if (bets.length && bets.every((bet) => bet.phase !== 'PREPARED')) {
            setBetProcessing(false);
          }

          const acceptedBets = [];
          const rejectedBets = [];
          let acceptedBetsCount = 0;
          let rejectedBetsCount = 0;
          bets.forEach((bet) => {
            if (bet.phase === 'PLACED' && bet.resolutionStatuses?.[0]?.betState === 'OPEN') {
              acceptedBetsCount += 1;
              acceptedBets.push({
                ...bet,
                betId: bet.id,
                payin: { ...bet.payin, totalStake: bet.payin.total },
              });
            }

            if (bet.phase === 'REJECTED') {
              rejectedBetsCount += 1;
              rejectedBets.push(bet);
            }
          });

          if (acceptedBetsCount) {
            handleBetslipAfterPlacedBet({
              acceptedBets,
              rejectedBetsCount,
              betslipToClear: betslipsToClear.value.get(bet.reqUuid),
            });
            emitter.emit('fetch-wallet');
            betslipsToClear.value.delete(bet.reqUuid);
          }

          if (!rejectedBetsCount) return;

          if (rejectedBetsCount === 1) {
            const rejectedReasons = rejectedBets[0].reasons?.map(({ reason }) => reason);
            displayBetRejectedReasons(rejectedReasons);
            return;
          }

          displayMultipleRejectedBetsMessage({
            showRejectedBetsCount: !!acceptedBetsCount,
            rejectedBetsCount,
          });
        } catch (error) {
          resetSelectionsDataHashes();
          console.error(error);
        }
      }, 5000);
    } catch (e) {
      pendingPlacedBets.value[bet.reqUuid] = false;
      setBetProcessing(false);
      store.addNotification({
        timeout: 5000,
        text: 'Ticket has been rejected',
        type: 'error',
      });

      resetSelectionsDataHashes();
      console.log('Bet could not be placed', e);
    }
  }

  function sendBetslipWorkerMessage(message, data) {
    betslipWorker.postMessage(JSON.stringify({ message, data }));
  }

  betslipWorker.onmessage = ({ data: message }) => {
    const { event, data } = message;

    if (event === 'pascal_triangle') pascalTriagle.value = data;
    if (event === 'betslip_validation') {
      breachedLimits.value = data.breachedLimits;
      limits.value = data.limits;
    }
  };

  const betslipLoading = ref(false);

  function setBetslipLoading(val) {
    betslipLoading.value = val;
  }

  function removeBetBuilderBet(selectionId, outcomeId, marketId) {
    const betslip = getCurrentBetslip();
    if (!betslip) return;

    const isDeleted = betslip.selectionsData[selectionId]?.markets.delete(
      `${marketId}/${outcomeId}`,
    );

    if (!betslip.selectionsData[selectionId]?.markets?.size) {
      removeSelection(selectionId);
    }

    return isDeleted;
  }

  function handleBetslipAfterPlacedBet({ acceptedBets, rejectedBetsCount, betslipToClear }) {
    const resetBetslip =
      !rejectedBetsCount &&
      (store.punterPreferences?.clearBetslipAfterPayment ??
        store.config?.betslip?.clearBetslipAfterPayment);

    store.addPlacedBetsToBetList(acceptedBets);

    displayAcceptedBetsMessage({
      acceptedBetsCount: acceptedBets.length,
      showAcceptedBetsCount: !!rejectedBetsCount,
      showInBetslip: !resetBetslip,
    });

    if (resetBetslip) {
      clearBetslip(betslipToClear);
      closeMobileBetslip();
    }
  }

  function displayAcceptedBetsMessage({ acceptedBetsCount, showAcceptedBetsCount, showInBetslip }) {
    const message = showAcceptedBetsCount
      ? store.getTranslation('accepted_bets_count', { value: acceptedBetsCount })
      : store.getTranslation('ticket_accepted');

    store.addNotification({
      timeout: 6000,
      text: message,
      type: 'success',
      betslip: showInBetslip,
    });
  }

  function displayMultipleRejectedBetsMessage({ showRejectedBetsCount, rejectedBetsCount }) {
    const rejectedMessage = showRejectedBetsCount
      ? store.getTranslation('rejected_bets_count', { value: rejectedBetsCount })
      : store.getTranslation('ticket_rejected');

    store.addNotification({
      timeout: 6000,
      text: rejectedMessage,
      type: 'error',
      betslip: true,
    });
  }

  function displayBetRejectedReasons(reasons) {
    const messages = reasons?.length ? reasons : [store.getTranslation('ticket_rejected')];

    messages.forEach((messaage) => {
      store.addNotification({
        timeout: 6000,
        text: messaage,
        type: 'error',
        betslip: true,
      });
    });
  }

  const showOdds = computed(() => {
    const betslip = getCurrentBetslip();
    if (!betslip) return true;

    return betslip.selectionsList.some((selection) => selection.includes('bet_builder'))
      ? betslip.selectionsData[betslip.selectionsList[0]].markets.size > 1 &&
          totalSelectionsOdds.value > 0
      : true;
  });

  /**
   * Stores the betslip data in local storage.
   *
   * This function saves the user's betslip data into the local storage. It stores bet information
   * such as selected ticket type, systems (if applicable), selections, and stake.
   */
  function storeBetslipDataInLS() {
    const selectedBetType = selectedTicketType.value;

    for (const [id, betslip] of betslips.value) {
      const selectionsDataValues = Object.values(betslip?.selectionsData ?? {});

      if (!selectionsDataValues.length) continue;

      setItemToStorage(id, {
        selectedTicketType: selectedBetType,
        ...(selectedBetType === 'system' && { systems: betslip.systems }),
        selections: selectionsDataValues.map((selection) => ({
          ...selection,
          markets: [...selection.markets],
        })),
        stake: betslip.stake,
        desiredProfit: betslip.desiredProfit ?? null,
      });
    }
  }

  function subscribeBetslipEvents() {
    let betslipInPlayEvents = new Map();
    let betslipEvents = new Map();

    for (const [, betslip] of betslips.value) {
      for (const selection of betslip.selectionsList) {
        const { playStatus, eventId, eventVersion: version } = betslip.selectionsData[selection];
        betslipEvents.set(eventId, { id: eventId, version });
        if (playStatus === 2) betslipInPlayEvents.set(eventId, eventId);
      }
    }

    if (!betslipEvents.size) return;

    subscribeEventsOnOfferChanges([...betslipEvents.values()]);

    if (!betslipInPlayEvents.size) return;

    subscribeEventsOnMetadataChanges([...betslipInPlayEvents.values()]);
  }

  function handleAlternativeStakes(bets, betslipId) {
    alternativeStakeData.value = {
      enabled: false,
    };

    const currency = getSymbolFromCurrency(
      store.currency?.toUpperCase() || window.currency?.toUpperCase(),
    );

    let sumAmount = 0;
    bets.forEach((bet) => {
      if (bet.suggestion?.type !== 'ALT-STAKE') return;

      const amount = bet.suggestion?.stake?.amount;
      const desiredProfit = parseFloat(
        amount * (convertToIntegerOdds(firstSelection.value.odds, 10000) - 1),
      ).toFixed(2);

      const message = store.getTranslation(
        store.isBettingWithDesiredProfit ? 'desired_profit_alt_stake' : 'betslip_alt_stake_warning',
        store.isBettingWithDesiredProfit
          ? { amount: desiredProfit, currency }
          : { amount, currency },
      );

      const notification = {
        timeout: 6000,
        text: message,
        type: 'warning',
        betslip: true,
      };

      switch (selectedTicketType.value) {
        case 'combo':
          updateStake(amount);
          store.addNotification(notification);
          break;
        case 'single':
          const betslip = getCurrentBetslip();
          if (store.offerView === 'indonesian') {
            updateStake(amount);
            const selection = betslip.selectionsData[betslip.selectionsList[0]];
            selection.payment = amount;

            if (store.isBettingWithDesiredProfit) {
              updateDesiredProfit(desiredProfit);
            }

            store.addNotification(notification);
          } else {
            const clientHash = bet.hashes.find(({ type }) => type === 'CLIENT');
            for (const key of Object.keys(betslip.selectionsData)) {
              const selection = betslip.selectionsData[key];

              if (clientHash && clientHash.value === selection.previousHash) {
                selection.payment = amount;
                sumAmount += Number(amount);

                store.addNotification({
                  ...notification,
                  hash: selection.hash,
                });
              }
            }
            updateStake(sumAmount);
          }
          break;
        case 'system':
          break;
      }

      if (selectedTicketType.value === 'single') {
        alternativeStakeData.value.enabled = true;
        alternativeStakeData.value.betslipId = betslipId;

        const hash = bet.hashes.find(({ type }) => type === 'CLIENT')?.value;
        alternativeStakeData.value[hash] = {
          amount,
          betId: bet.betId,
        };
      } else {
        alternativeStakeData.value = {
          enabled: true,
          amount,
          betslipId,
          betId: bet.betId,
        };
      }
    });
  }

  function updatePendingPlacedBets({ id, value }) {
    pendingPlacedBets.value[id] = value;
  }

  const firstSelection = computed(() => {
    const betslip = getCurrentBetslip();
    if (!betslip || !betslip.selectionsList.length) return null;

    const [selectionId] = betslip.selectionsList;
    return betslip.selectionsData[selectionId];
  });

  function createBetslip(key, data) {
    betslips.value.set(
      key,
      data ?? {
        selectionsList: [],
        selectionsData: {},
        stake: defaultStake.value,
        desiredProfit: null,
        betBuilderSelections: new Map(),
        systems: ticketOptions?.systems ?? {},
      },
    );
  }

  function getCurrentBetslip() {
    return betslips.value.get(`betslip-${selectedBetslipIndex.value}`);
  }

  const betBuilderSelections = ref(new Map());
  function resetBetBuilderSelections(eventId) {
    for (const [selectionId, selection] of betBuilderSelections.value.get(eventId) ?? [])
      for (const [outcomeId] of selection.outcomes) {
        betBuilderSelections.value.get(eventId).get(selectionId).outcomes.get(outcomeId).available =
          true;
      }
  }

  const betBuilderBetsBeingPlayed = computed(() => {
    let betslip = getCurrentBetslip();
    if (!betslip) return false;

    return betslip.selectionsList.some((id) => betslip.selectionsData[id]?.type === 'bet_builder');
  });

  function getEventSelectionsForCalculation(eventId, selectionIdx) {
    const betslip = getCurrentBetslip();

    return [...(betslip.selectionsData[selectionIdx]?.markets ?? [])].reduce(
      (acc, [, { marketId, outcomeId, active }]) => {
        if (active)
          acc.push({
            eventMarketId: marketId,
            eventMarketOutcomeId: outcomeId,
            feed: betBuilderSelections.value?.get(eventId)?.get(marketId)?.outcomes?.get(outcomeId)
              ?.feed,
          });
        return acc;
      },
      [],
    );
  }

  async function calculateBetBuilderOdds(selection, customBetsSelections) {
    const betBuilderData = [...selection.markets].reduce((acc, [, { marketId, outcomeId }]) => {
      if (customBetsSelections?.[marketId]?.outcomes[outcomeId]?.feed)
        acc.push({
          eventMarketId: marketId,
          eventMarketOutcomeId: outcomeId,
          feed: customBetsSelections?.[marketId]?.outcomes[outcomeId]?.feed,
        });

      return acc;
    }, []);

    if (!betBuilderData?.length) return 10000;

    const bbCalculations = await getBetBuilderCalculations(selection.eventId, betBuilderData);
    return convertDecimalOddsToInteger(bbCalculations?.odds);
  }

  async function reduceRebetSelections(rawSelections, events) {
    const { getSupplemental, getCompetitorSupplementalName } = useSupplemental('betslip');

    const selectionsList = [];
    const selectionsData = {};

    for (const selection of rawSelections) {
      const event = events.find((event) => event.id === +selection.event.syntheticId);
      if (!event) continue;

      for (const {
        syntheticId: outcomeSynteticId,
        eventMarket: market,
        name: outcomeName,
      } of selection.eventMarketOutcomes) {
        const eventMarket = event.markets.find(({ id }) => id === +market.syntheticId);
        if (!eventMarket) continue;

        const eventMarketOutcome = eventMarket.outcomes.find(({ id }) => id === +outcomeSynteticId);
        if (!eventMarketOutcome) continue;

        const constructSelectionIndex = () => {
          if (selection.type === 'CUSTOM') return `${event?.id}/bet_builder`;
          return `${event?.id}/${eventMarket?.id}/${eventMarketOutcome?.id}`;
        };

        const selectionIdx = constructSelectionIndex();

        if (!selectionsList.includes(selectionIdx)) {
          selectionsList.push(selectionIdx);
          selectionsData[selectionIdx] = {
            active: true,
            hash: ulid(Date.now()),
            banker: selection.banker,
            id: selectionIdx,
            competitors: [2, 3].includes(event?.competitorType)
              ? [getSupplemental(event)]
              : orderBy(event.competitors, 'ordinal').map((competitor) =>
                  getCompetitorSupplementalName(competitor),
                ),
            eventCompetitors: [],
            type: selection.type === 'CUSTOM' ? 'bet_builder' : 'regular',
            eventId: event.id,
            startsAt: event.startsAt,
            playStatus: event.playStatus,
            sportId: +selection.sport.syntheticId,
            metadata: event?.metadata ?? null,
            categoryId: +selection.category.syntheticId,
            tournamentId: +selection.tournament.syntheticId,
            game: event.playStatus === 2 ? 'LIVE' : 'PREMATCH',
            markets: new Map(),
            previousOdds: null,
            odds: eventMarketOutcome.odds,
            limits: {},
          };
        }
        selectionsData[selectionIdx].markets.set(`${eventMarket.id}/${eventMarketOutcome.id}`, {
          active: true,
          marketId: eventMarket.id,
          marketName: market.name,
          metaMarketId: eventMarket.marketId,
          outcomeId: eventMarketOutcome.id,
          metaOutcomeId: eventMarketOutcome.outcomeId,
          odds: eventMarketOutcome.odds,
          outcomeName,
        });

        if (selection.type === 'CUSTOM') {
          selectTicketType('combo');

          const customBets = await getCustomBets(event.id);
          store.sendOfferWorkerMessage('set-event-bet-builder-selections', {
            eventId: event.id,
            selections: customBets?.selections ?? [],
          });

          selectionsData[selectionIdx].odds = await calculateBetBuilderOdds(
            selectionsData[selectionIdx],
            customBets.selections,
          );
        }
      }
    }

    const betslip = getCurrentBetslip();
    betslip.selectionsList = selectionsList;
    betslip.selectionsData = selectionsData;
  }

  const customBetsLoading = ref(false);
  async function loadCustomBets(id = null) {
    const betslip = getCurrentBetslip();
    if (!betslip) return;

    const eventId = id ?? store.eventviewId;
    if (betBuilderSelections.value.has(eventId)) return true;

    customBetsLoading.value = true;
    const response = await getCustomBets(eventId);
    store.sendOfferWorkerMessage('set-event-bet-builder-selections', {
      eventId,
      selections: response?.selections ?? [],
    });
    customBetsLoading.value = false;

    return true;
  }

  const indonesianOfferType = ref(null);
  function setIndonesianOfferType(type) {
    if (type !== 'single' && type !== 'combo') return;

    indonesianOfferType.value = type;
    selectedBetslipIndex.value = type === 'single' ? 0 : 1;
    selectTicketType(type);

    const oddFormat = type === 'combo' ? 'decimal' : 'indonesian';

    if (type === 'single' && store.eventviewId) {
      store.selectedMarketGroup = 'all';
      store.selectedMoreLabelMarketGroup = 'general_all';
    }

    store.updatePunterPreferences({
      data: {
        ...store.punterPreferences,
        indonesianOfferType: type,
        oddFormat,
      },
    });
  }

  function toggleIndonesianOfferType() {
    setIndonesianOfferType(indonesianOfferType.value === 'single' ? 'combo' : 'single');
  }

  function getUniqueBetslipEvents(bets) {
    return bets.flatMap(({ selections }) =>
      selections.reduce(
        (acc, selection) => (acc.includes(selection.eventId) ? acc : [...acc, selection.eventId]),
        [],
      ),
    );
  }

  function prepareThirdPartyBetSelectionsForRebet({ selections, metadata, events }) {
    const { markets, tournaments, categories } = metadata;

    return selections.reduce((acc, selection) => {
      const event = events.find(({ id }) => id === selection.eventId);
      if (!event) return acc;

      const eventMarket = event.markets?.find(({ id }) => id === selection.eventMarketId);
      if (!eventMarket) return acc;

      const eventMarketOutcome = eventMarket.outcomes?.find(
        ({ id }) => id === selection.eventMarketOutcomeId,
      );

      const market = markets.find(({ id }) => id === eventMarket?.marketId);
      const marketOutcome = market?.outcomes.find(({ id }) => id === eventMarketOutcome.outcomeId);

      const tournament = tournaments.find(({ id }) => id === event.tournamentId);
      const category = categories.find(({ id }) => id === tournament.categoryId);

      const formattedSelection = formatThirdPartySelection({
        category,
        selection,
        tournament,
        marketName:
          getSupplemental(eventMarket) ??
          getMarketSupplementalName(
            {
              ...eventMarket,
              supplementalNames: market?.supplementalNames ?? {},
              eventMarketName: market.eventMarketName,
              name: market.name,
              outcomes: sortAndFilterEventMarketOutcomes(eventMarket?.outcomes ?? []),
            },
            event,
          ),
        outcomeName:
          getSupplemental(eventMarketOutcome) ??
          getMarketOutcomeSupplementalName(
            {
              ...eventMarketOutcome,
              ...(eventMarketOutcome.competitors && {
                competitors: eventMarketOutcome.competitors.map((competitor) => ({
                  ...competitor,
                  ...(competitor.playerId &&
                    event.marketPlayers && {
                      playerName: event.marketPlayers[competitor.playerId]?.name ?? '',
                    }),
                  ...(competitor.teamId &&
                    event.marketTeams && {
                      teamName: event.marketTeams[competitor.teamId]?.name ?? '',
                    }),
                })),
              }),
              supplementalNames: marketOutcome?.supplementalNames ?? {},
              eventMarketOutcomeName: marketOutcome.eventMarketOutcomeName,
              name: marketOutcome.name,
            },
            event,
            eventMarket,
          ),
      });

      return [...acc, formattedSelection];
    }, []);
  }

  async function handleThirdPartyAddToBetslipAction({ bet, events, metadata }) {
    const betSelections = prepareThirdPartyBetSelectionsForRebet({
      selections: bet.selections,
      metadata,
      events,
    });

    const rebetSuccess = await store.rebet({
      bet: formatThirdPartyBet(bet, betSelections),
      events,
    });

    if (!rebetSuccess) return;

    if (isMobile) {
      router.push({
        path: 'v_betslip',
        query: store.route.query,
      });
      store.setSelectedView('betslip');
    } else {
      store.setActiveTicketTab('betslip');
    }

    store.subscribeBetEvents(events);
  }

  async function handleThirdPartyBetsPlacement({ bets }) {
    if (!bets?.length) return;

    const eventsList = getUniqueBetslipEvents(bets);

    const { events, markets, categories, tournaments } = await store.getAvailableEvents(
      eventsList,
      {
        excludeMarketMetadata: false,
        excludeSportCategoryTournamentMetadata: false,
      },
    );

    if (!events?.length) return;

    for (const bet of bets) {
      if (bet.action === 'add_to_betslip') {
        handleThirdPartyAddToBetslipAction({
          bet,
          events,
          metadata: { markets, categories, tournaments },
        });
      }
    }
  }

  return {
    // Actions
    addSelection,
    removeSelection,
    toggleSelection,
    setBetBuilderSelectionOdds,
    updateBetCalculationData,
    updateStake,
    updateDesiredProfit,
    clearBetslip,
    closeMobileBetslip,
    requestBetCalculation,
    selectTicketType,
    updateSystem,
    updateSystemStake,
    clearSystems,
    clearSelectionsStake,
    updatePreviousOddsRef,
    setBetProcessing,
    payin,
    sendBetslipWorkerMessage,
    updatePendingPlacedBets,
    removeSelectionsForSingleIndonesianTicketType,

    // State
    calculationWinnings,
    selectedTicketType,
    selectedBetType,
    betProcessing,
    pendingPlacedBets,

    // Computed
    activeSelectionsCount,
    availableSystems,
    splitStakePerSelection,
    totalSelectionsOdds,
    totalSelectionsStake,
    selectionHasSingleStakeSet,
    selectionsByEvent,
    totalSystemCombinations,
    defaultStake,

    pascalTriagle,
    limits,
    breachedLimits,
    checkForSystemCombinations,

    betslipSave,
    betslipLoading,
    setBetslipLoading,
    removeBetBuilderBet,

    handleBetslipAfterPlacedBet,
    displayMultipleRejectedBetsMessage,
    displayBetRejectedReasons,
    showOdds,
    exchangedIdsCache,

    storeBetslipDataInLS,
    subscribeBetslipEvents,

    handleAlternativeStakes,

    firstSelection,

    betslips,
    selectedBetslipIndex,
    createBetslip,
    indonesianOfferType,
    setIndonesianOfferType,
    toggleIndonesianOfferType,
    getCurrentBetslip,
    resetBetBuilderSelections,
    getEventSelectionsForCalculation,
    calculateBetBuilderOdds,
    reduceRebetSelections,
    customBetsLoading,
    loadCustomBets,
    setSelectedBetslipIndex,
    betBuilderSelections,
    betBuilderBetsBeingPlayed,
    handleThirdPartyBetsPlacement,
    betslipsToClear,
  };
});
