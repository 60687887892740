const statusMap = {
  1: 'NS',
  2: '1P',
  3: 'HT',
  4: '2P',
  5: '1Q',
  6: '1B',
  7: '2Q',
  8: '2B',
  9: '3Q',
  10: '3B',
  11: '4Q',
  12: 'END',
  13: 'A. OT',
  14: 'OT',
  15: 'END OT',
};

const periodsMap = {
  1: '1P',
  2: '2P',
  3: '1Q',
  4: '2Q',
  5: '3Q',
  6: '4Q',
  7: 'OT',
};

function mapPeriods(eventTypes) {
  const periods =
    eventTypes
      .find((type) => type.id === 3)
      ?.periods?.filter((period) => period.id !== 1 && period.id !== 2) ?? [];

  if (!periods.length) return [];

  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: periodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapEBasketballEvent(event, shortTime) {
  const tempTime = event.events.find((event) => event.id === 4)?.value?.value || 0;
  const eventTime = shortTime && typeof tempTime === 'string' ? `${tempTime.split(':')[0]}'` : tempTime;
  const currentGameScore = event.events.find((event) => event.id === 1)?.value ?? null;

  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapPeriods(event.events),
    currentGameScore,
    compactModeScores: [currentGameScore],
    eventTime,
  };
}
